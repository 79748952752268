import React from "react";
import Spots from "./Spots";
import {BorderDiv, LayoutRow} from "../../../AppStyledLaptop";


const   HighLight = () => {
    return (
        <LayoutRow style ={{marginTop:"100px"}}>
            <BorderDiv>
                <Spots/>
            </BorderDiv>
         </LayoutRow>
    )
}
export default HighLight;