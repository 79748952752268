import React from "react";

import FinChart from "../AppLaptop/Component/Finance/TabCoin/FinChart";
// import moment from "moment";


export const Graphic = (props) => {
    // const  xAxisTickFormatter = (date) =>{
    //     return moment.unix(date).format('DD/MM/YY');
    // }
    const CandleChart = props.CandleChart


    return (
        <div>
                        {CandleChart.map(item =>(<div>
                            <FinChart data={item.value}></FinChart>
                            {/*<div>*/}
                            {/*    <LineChart width={730} height={250} data={item.value}*/}
                            {/*               margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>*/}
                            {/*        <CartesianGrid strokeDasharray="1 1" />*/}
                            {/*        <XAxis dataKey="time" tickFormatter = {xAxisTickFormatter} />*/}
                            {/*        <YAxis />*/}
                            {/*        <Legend />*/}
                            {/*        <Line type="monotone" dataKey="traders" stroke="#8884d8"  dot ={false}/>*/}
                            {/*        /!*<Line type="monotone" dataKey="value" stroke="#82ca9d" / >*!/*/}
                            {/*    </LineChart>*/}
                            {/*</div>*/}
                        </div>))}

        </div>

    )
}