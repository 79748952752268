import React from 'react';
import { SearchCSS1} from "../../../AppStyledLaptop";
import {useTranslation} from "react-i18next";

const SearchCom = (props) => {
    let handleChange = props.handleChangeCoin
    const {t} = useTranslation();

    return (
        <div>
            <SearchCSS1>
                <input
                    style={{background:"white" ,
                        marginLeft :"400px",
                        border : "1px solid gray", marginTop:"25px", height:"40px", width :'700px'}}
                    type='text'
                    onChange={handleChange}
                    placeholder={t(' 🔍 Search Coin')}
                    id='searchALTS'
                />

            </SearchCSS1>

        </div>


    )

}
export default SearchCom;