import React, {useContext} from "react";
import {
    Covid30CSS,
    FullPage,
    LayoutColumn,
    LayoutRow,
    MarginButtonTable,
    MarginLeft
    , MarginLeft80,
    MarginTopBottom,
    TitleTopic,
} from "../../AppStyledLaptop";
import {CovidWorldContext} from "../../../Provider/CovidProvider/CovidYesterday";
import {Area, Bar, CartesianGrid,Tooltip, ComposedChart, Line, XAxis, YAxis} from "recharts"
import {Button,TitleCard} from "../../../../AppStyled";
import {useTranslation} from "react-i18next";
import {CandleContext2} from "../../../Provider/CandleProvider2";
import Carousel from "react-multi-carousel";
import {responsive} from "../../../../service/constantsVariable";
import {v4 as uuidv4} from "uuid";
import useWindowDimensions from "../../../../service/useWindowDimensions";


const VietnamCovid = () => {
    const {t} = useTranslation();
    const {colorBG} = useContext(CandleContext2)
    const { width } = useWindowDimensions();
    const {filterVNdtTopGreater1k,filterVNdtSmaller1k,vnHistoryCv} = useContext(CovidWorldContext)


    return (
        <MarginTopBottom>
            <FullPage>
                <TitleTopic style={{}}><
                    span>
                    <em style={{fontSize: "10px"}}>[ref:https://covid19.gov.vn]</em>
                </span>
                </TitleTopic>
                {width > 1200?(
                    <LayoutRow>
                    <MarginLeft80>
                        <TitleCard  style ={{color:`${colorBG.color}`}}>{t("Daily Hospitalized")}</TitleCard>
                        <ComposedChart width = {400} height={250} data={vnHistoryCv}>
                            <XAxis dataKey="date"  style={{fill:`${colorBG.color}`}}/>
                            <YAxis  style={{fill:`${colorBG.color}`}}/>
                            <Tooltip cursor={true}/>
                            <CartesianGrid stroke="#f5f5f5"/>
                            <Bar dataKey="treating" barSize={20} fill={"#7bc043"}/>
                            <Line type="monotone" stroke="#7bc043"/>
                        </ComposedChart>
                    </MarginLeft80>
                    <MarginButtonTable>
                        <div>
                            <TitleCard>{t("Daily Deaths")}</TitleCard>
                            <ComposedChart width = {400}  height={250} data={vnHistoryCv}>
                                <XAxis dataKey="date"  style={{fill:`${colorBG.color}`}}/>
                                <YAxis  style={{fill:`${colorBG.color}`}}/>
                                <Tooltip cursor={true}/>
                                <CartesianGrid stroke="#f5f5f5"/>
                                <Bar dataKey="death" barSize={20} fill="#854442"/>
                            </ComposedChart>
                        </div>
                    </MarginButtonTable>

                        <div>
                            <TitleCard>{t("Daily Cases")}</TitleCard>
                            <ComposedChart width={400}  height={250} data={vnHistoryCv}>
                                <XAxis dataKey="date" style={{fill:`${colorBG.color}`}}/>
                                <YAxis  style={{fill:`${colorBG.color}`}}/>
                                <Tooltip/>
                                <CartesianGrid stroke="#f5f5f5"/>
                                <Area type="monotone" dataKey="cases" fill="#ffdbac" stroke="#ff7300"/>
                                <Line type="monotone" stroke="#ff7300"/>
                            </ComposedChart>

                        </div>
                </LayoutRow>):(
                    <LayoutColumn>

                        <MarginLeft80>
                            <TitleCard>{t("Daily Deaths")}</TitleCard>
                            <ComposedChart width = {600}  height={250} data={vnHistoryCv}>
                                <XAxis dataKey="date"  style={{fill:`${colorBG.color}`}}/>
                                <YAxis  style={{fill:`${colorBG.color}`}}/>
                                <Tooltip cursor={true}/>
                                <CartesianGrid stroke="#f5f5f5"/>
                                <Bar dataKey="death" barSize={20} fill="#854442"/>
                            </ComposedChart>
                        </MarginLeft80>


                        <MarginLeft80>
                            <TitleCard>{t("Daily Cases")}</TitleCard>
                            <ComposedChart width={600}  height={250} data={vnHistoryCv}>
                                <XAxis dataKey="date" style={{fill:`${colorBG.color}`}}/>
                                <YAxis  style={{fill:`${colorBG.color}`}}/>
                                <Tooltip/>
                                <CartesianGrid stroke="#f5f5f5"/>
                                <Area type="monotone" dataKey="cases" fill="#ffdbac" stroke="#ff7300"/>
                                <Line type="monotone" stroke="#ff7300"/>
                            </ComposedChart>

                        </MarginLeft80>
                        <MarginLeft80>
                            <TitleCard  style ={{color:`${colorBG.color}`}}>{t("Daily Hospitalized")}</TitleCard>
                            <ComposedChart width = {600} height={250} data={vnHistoryCv}>
                                <XAxis dataKey="date"/>
                                <YAxis/>
                                <Tooltip cursor={true}/>
                                <CartesianGrid stroke="#f5f5f5"/>
                                <Bar dataKey="treating" barSize={20} fill="#7bc043"/>
                                <Line type="monotone" stroke="#7bc043"/>
                            </ComposedChart>
                        </MarginLeft80>
                    </LayoutColumn>
                ) }
            </FullPage>
            <MarginTopBottom>
                <FullPage>
                    <MarginTopBottom style={{ position: "relative" }}>
                        <MarginLeft>
                            <TitleCard style={{fontWeight: "bold"}}>{t("Yesterday <1000")}</TitleCard>
                            <Carousel responsive={responsive}>
                                {filterVNdtSmaller1k.length>0 ?filterVNdtSmaller1k.map((item,i) => (
                                    <Covid30CSS key={uuidv4()}>
                                        <LayoutRow >
                                            <TitleCard key ={i+item.name}>{item.name}</TitleCard>
                                            <Button style ={{}} key ={i+item.name}>+{new Intl.NumberFormat('en').format(item.casesToday)}</Button>
                                        </LayoutRow>
                                    </Covid30CSS>
                                )) : (<div></div>)}
                            </Carousel>
                        </MarginLeft>

                    </MarginTopBottom>
                    <MarginTopBottom>
                        <MarginLeft style={{ position: "relative" }}>
                            <TitleCard style={{fontWeight: "bold"}}>{t("Yesterday >=1000")}</TitleCard>
                            <Carousel responsive={responsive}>
                                {filterVNdtTopGreater1k.length>0 ?filterVNdtTopGreater1k.map((item,i) => (
                                    <Covid30CSS key={uuidv4()}>
                                        <LayoutRow>
                                            <TitleCard  style ={{}}  key ={i+item.name}>{item.name}</TitleCard>
                                            <Button style ={{}} key ={i+item.name}>+{new Intl.NumberFormat('en').format(item.casesToday)}</Button>
                                        </LayoutRow>

                                    </Covid30CSS>
                                )) : (<div></div>)}
                            </Carousel>
                        </MarginLeft>
                    </MarginTopBottom>
                </FullPage>






            </MarginTopBottom>
        </MarginTopBottom>

    );
};

export default VietnamCovid;
