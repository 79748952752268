import React from "react";
import FinChart from "../FinChart";
import {MarginChartInfo} from "../../../../../AppLaptop/AppStyledLaptop";


export const Graphic50MB = (props) => {
    const CandleChart = props.CandleChart
    const Selected = CandleChart.map(elm=>{return{symbol:elm.symbol, value:elm.value.slice(-50)}})
    return (
        <MarginChartInfo>
            {Selected.map((item) => (
                    <div style ={{marginLeft:"15px"}}>
                        <FinChart data={item.value}></FinChart>
                    </div>
                )
            )}
        </MarginChartInfo>

    )
}