import React from "react";
import {LineChart, Line, XAxis,Tooltip,  CartesianGrid} from "recharts";

export const TinyLineChart = ({data}) => (
    <div>
            <LineChart width={350} height={200} data={data} >
                <XAxis
                    dataKey="date"
                    stroke="inherit"
                    tick={true}
                    hide={false}
                    style={{fill:'white'}}/>
                <CartesianGrid stroke="#eee" strokeDasharray="5 5"/>
                <Line
                    yAxisId="left"
                    activeDot={{ r: 4 }}
                    dot={false}
                    type="monotone"
                    dataKey="case"
                    stroke="#f37736"
                    strokeWidth={3}
                />
                <Tooltip cursor={true} formatter={(value) => new Intl.NumberFormat('en').format(value)} />
            </LineChart>
    </div>

);

