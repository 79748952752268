import { createSlice,createAsyncThunk } from '@reduxjs/toolkit'
import axios from "axios";

export const fetchCoins24 = createAsyncThunk(
    "coins24s/fetchCoins24",
    async () => {
        const response = await axios.get("https://api.binance.com/api/v3/ticker/24hr");
        return response.data;
    }
);

export const fetchCoin = createAsyncThunk(
    "coins24s/fetchCoin",
    async (symbol) => {
        const res = await axios.get(
            `https://api.binance.com/api/v3/klines?symbol=${symbol}&interval=1d`);
        const Ar =[]
        const Obj = {};
        Obj.symbol =  symbol;
        const CandleData = res.data.map(chil => {
                return {
                    time: chil[0]/1000,
                    open: parseFloat(chil[1]),
                    high: parseFloat(chil[2]),
                    low: parseFloat(chil[3]),
                    close: parseFloat(chil[4]),
                    value:parseFloat(chil[5]),
                    traders: parseFloat(chil[8])
                }


            }
        )
        Obj.value = CandleData
        Ar.push(Obj)
        console.log('Ar',Ar)
        //have neededALTSdt
        return Ar;
    }
);

export const fetchExchangeInfo = createAsyncThunk(
    "coins24s/fetchExchangeInfo",
    async (symbol) => {
        const response = await axios.get(`//api.binance.com/api/v3/exchangeInfo?symbol=${symbol}`);
        return response.data.symbols;
    }
);

export const initialState = {
    coins24:[],
    coinBin:[],
    exInfo:[]
}
const coins24Slice = createSlice({
    name: 'coins24s',
    initialState,

    extraReducers: {
        [fetchCoins24.pending]: () => {
            console.log("Pending");
        },
        [fetchCoins24.fulfilled]: (state, { payload }) => {
            console.log("Fetched Successfully!");

            return {... state, coins24: payload };
        },
        [fetchCoins24.rejected]: () => {
            console.log("Rejected!");
        },
        [fetchCoin.fulfilled]: (state, { payload }) => {
            console.log("Fetched Successfully!");
            return {... state, coinBin: payload };
        },
        [fetchExchangeInfo.fulfilled]: (state, { payload }) => {
            console.log("Fetched Successfully!");

            return { ...state, exInfo: payload };
        },

    },

})



export const Coins24Selector = state => state.coins24s.coins24
export const CoinsBinSelector = state => state.coins24s.coinBin
export const ExInfoSelector = state => state.coins24s.exInfo


export default coins24Slice.reducer

