import React, {useState} from "react";
import {useSelector} from 'react-redux'
import {v4 as uuidv4} from 'uuid';
import {useTranslation} from "react-i18next";
import SearchCountry from "../../../../../AppMobile/Component/Health/Covid/CovidComponent/SearchCountry/SearchCountry";
import {Covid90Selector} from "../../../../../redux-tooklit-app/Covid90d";
import {
    FacebookIcon,
    FacebookShareButton,
    TelegramIcon,
    TelegramShareButton,
    TwitterIcon,
    TwitterShareButton
} from "react-share";
import {FullPage, LayoutRow, Padding} from "../../../../../AppLaptop/AppStyledLaptop";
import TopComMB from "../../../../pages/Healthy/30d/RankCom";

const Fluctuation90FULLMB = () => {
    const covid90d = useSelector(Covid90Selector)
    const [selectCon, setSelectCon] = React.useState("");
    const {t} = useTranslation()
    const [search, setSearch] = useState('')

    const handleChange = (e) => {
        setSearch(e.target.value);
    };
    return (
        <FullPage style ={{marginTop:'45px'}}>
            <div>
                <div >
                    <Padding>
                        <label htmlFor="select">
                            <strong>{t("COVID19-FLUCTUATION")}: </strong>
                        </label>
                        <select id='select' style={{height: '30px'}} value={selectCon}
                                onChange={(e) => setSelectCon(e.currentTarget.value)}>
                            <option value=''>
                                All
                            </option>
                            {covid90d.map(item => {
                                return (
                                    <option value={item.Continent}>
                                        {item.Continent}
                                    </option>
                                )
                            })}

                        </select>
                        <SearchCountry style={{}} handleChangeCountry ={handleChange}/>
                        <br/>

                        <LayoutRow>
                            <FacebookShareButton
                                url={"https://dts.pages.dev/"}
                                hashtag={["coin", "covid"]}
                            >
                                <FacebookIcon size={32} round />
                            </FacebookShareButton>
                            <TwitterShareButton
                                style = {{marginLeft:"10px"}}
                                url={"https://dts.pages.dev/"}
                                hashtags={["coin", "covid"]}
                            >
                                <TwitterIcon size={32} round />
                            </TwitterShareButton>
                            <TelegramShareButton
                                style = {{marginLeft:"10px"}}
                                url={"https://dts.pages.dev/"}
                                hashtags={["coin", "covid"]}
                            >
                                <TelegramIcon size={32} round />
                            </TelegramShareButton>
                        </LayoutRow>
                    </Padding>
                </div>
            </div>
            <div style={{paddingLeft:'15px'}}>
             <div>
                    {covid90d.filter(({Continent}) => selectCon ? Continent === selectCon : true).map(item => {
                        return (
                            <div key={uuidv4()}>
                                {item.Stat[0].data.filter((subItem) => {
                                    return subItem.country.toLowerCase().includes(search.toLowerCase());
                                }).map(filteredCountry90 => (
                                    <div key={uuidv4()}>
                                        <TopComMB GraphicData={filteredCountry90}></TopComMB>
                                        <br/>
                                    </div>
                                ))}
                            </div>

                        )
                    })}
                </div>
            </div>
        </FullPage>

    );
}
export default Fluctuation90FULLMB;
