import styled from "styled-components";
import {marginLeft} from "../../service/margin";

export const FullPage = styled.div`
  background-color:#045F5F;
  font-family: "Times New Roman";

`

export const PageCSS = styled.div`
  top: 200px;
  margin-left: 100px;
  margin-right: 100px;
  font-family: "Times New Roman";
  font-size: 18px;

`
export const SymCSS = styled.div`
    padding: 5px;
    margin-left: 100px;

`
export const EcoCSS = styled.div`
  margin-top: 50px;

`
export const CardStyledS = styled.div`
  height: 30px;
  margin-top: 20px;
  margin-bottom: 20px;
`
export const ButtonSymbol = styled.div`
  height: 40px;
  padding-left: 10px;

`;

export const SpanButtonSymbol = styled.div`
  padding-left: 5px;

`;

export const LinkFinCSS = styled.div`

  list-style: none;

  &::before {
    content: '◀';
    margin: 0 10px;
  }

`

export const LinksFinCSS = styled.div`
  display: flex;
  flex-direction: row;

    // ${LinkFinCSS}:hover & {
  //   fill: forestgreen;
  // }
  &:hover {
    background-color: #e3f0ff;
  }

`

export const HoverPair = styled.div`

  &:hover {
    background-color: rebeccapurple;
    cursor: pointer;
    border-radius: 10px;
  }

`

export const HoverPairMinMax = styled.div`
  &:hover {
    color: Gray;
  }

`
export const GroupCardStyled = styled.div`
  display: inline-grid;
  grid-template-columns: auto auto auto ;
  column-gap: 25px;
`

export const Border = styled.div`
  border-top: 1px solid #7cfc00;
  border-bottom: 1px solid #adff2f;
  border-left: 1px solid #32cd32;
  border-right: 1px solid #00fa9a;
  border-radius: 05px;

`

export const CoinIntroStyled = styled.div`
  margin-left: 40px;
`

export const ButtonTopicStyled = styled.div`
  font-weight: bolder;
  height: 10px;
  padding: 20px;
`
export const HoverButtonTopic = styled.div.attrs(() =>
    ({tabIndex: 0})
)`
  &:hover {
    color: darkgreen; // <Thing> when hovered
  }

`


export const ResponStyle = styled.div`
  display: flex;
  flex-direction: row`


export const ButtonTableCSS = styled.div`

  margin: 10px;
  padding: 10px;
  line-height: 100px

`
export const BorderDiv = styled.div`
 border: 0.1px solid gray;
`
export const Covid30CSS = styled.div`
  margin: 5px;
  display: flex;
  align-items: baseline;
  flex-direction: column;
  border-radius: 20px;
  background: #033E3E;
`


export const BorderCarousel = styled.div`
  border: 1px solid whitesmoke;
  border-radius: 10px;
`

export const CovidWorldTodayLAP = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  border-top: 1px solid lightskyblue;
  border-bottom: 1px solid lightskyblue;

`

export const ClassRateCSS = styled.div`
  margin-top: 5px;
  display: inline-grid;
  padding: 10px;
  grid-template-columns: auto auto;
  column-gap: 5px;
  row-gap: 2em;

`


export const CovidRateCSS = styled.div`
  height: 200px;
  padding: 30px;
  display: flex;
  align-items: center;
  flex-direction: column;
  border: 1px solid whitesmoke;
  border-radius: 20px;
  background: #e6e6ea;
`


export const LayoutRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;

`
export const LayoutRow2 = styled.div`
  display: flex;
  margin-left: 30px;
  flex-direction: row;
  align-items: baseline;

`

export const Sticky = styled.div`
  position: -webkit-sticky;
  position: sticky;
  top: 800px;

`
export const TopCss = styled.div`
  display: flex;
  margin-left: 30px;
  flex-direction: row;
  line-height: 1;
  box-shadow: 5px 5px 2px  gray ;
  border-left: 1px solid lightskyblue;
  border-bottom: 1px solid lightskyblue;
`
export const TopCss2 = styled.div`
  display: flex;
  margin-left: 30px;
  width: 378px;
  flex-direction: row;
  box-shadow: 3px 3px 2px  gray ;
  border-left: 1px solid lightskyblue;
  border-bottom: 1px solid lightskyblue;
`
export const ButtonTop = styled.div`
  text-align:center;
  width: 150px;
  cursor: pointer;
  padding:10px;
  box-shadow: 5px 5px 2px gray ;
  background: ghostwhite;
  color: red;
  border: 1px solid gray;
 
`
export const ButtonTop2 = styled.div`
  text-align:center;
  width: 150px;
  cursor: pointer;
  padding:10px;
  box-shadow: 5px 5px 2px gray ;
  background: ghostwhite;
  color: red;
  border: 1px solid gray;
 
`
export const TitleLeft = styled.div`
  text-align:left;
  width: 180px;
  padding:10px;
  line-height: 1;
`
export const TitleLeft2 = styled.div`
  text-align:left;
  width: 180px;
  padding:10px;
  line-height: 1;
`
export const TitleMain= styled.div`
  text-align:left;
  width: 180px;
  padding:20px;
  font-weight: bolder;
  line-height: 1;
  margin-left: 25px;
`
export const LayoutColumn = styled.div`
  display: flex;
  flex-direction: column;

`

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto ;
  grid-gap: 20px;
  padding: 05px;

`
export const GridContainer2 = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  padding: 10px;
`

export const BigItem = styled.div`
  grid-column: 1 / span 2;
`
export const MarginLeftRight = styled.div`
  margin-left: 40px;
  margin-right: 40px;
`
export const MarginLeftRight2 = styled.div`
  margin-left: 10px;
  margin-right: 10px;
`
export const MarginLeftRight330 = styled.div`
  margin-left: 30px;
  margin-right: 30px;
`
export const MarginLeftRightSmall = styled.div`
  margin-left: 20px;
  margin-right: 20px;
`
export const MarginLeft = styled.div`
  margin-left: 20px;
`
export const MarginLeft2 = styled.div`
  margin-left: 10px;
`
export const MarginLeftFluc = styled.div`
  margin-left: 10px;
`
export const MarginLeft80= styled.div`
  margin-left: 80px;
`
export const MobileFl= styled.div`
  margin-left: 10px;
  margin-right: 10px;
`
export const MarginLeft100= styled.div`
  margin-left: 100px;
`

export const Margin4= styled.div`
  margin: 20px;

`
export const PaddingBottom = styled.div`
  padding-bottom: 40px;

`

export const Padding4small = styled.div`
  padding: 20px;
`
export const CardTop = styled.div`
  border-radius: 05px;
  box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.3);
  transition: box-shadow 0.5s;
`
export const CardTopSwitch = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 20px;
  box-shadow: 0px 10px 30px -5px rgba(0, 300, 200, 0.5);
  transition: box-shadow 0.5s;
`
export const CardTopSwitch2 = styled.div`
  margin-bottom: 20px;
  box-shadow: 0px 10px 30px -5px rgba(0, 300, 200, 0.5);
  transition: box-shadow 0.5s;
`


export const CardGraphicLargest = styled.div`
  margin-left: 10px;
  margin-top: 20px;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.3);
  transition: box-shadow 0.5s;
`
export const CardGraphicLargestMobile = styled.div`
  margin-left: 10px;
  margin-top: 20px;
  margin-bottom: 10px;
  border-radius: 10px;
  margin-right: 10px;
  box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.3);
  transition: box-shadow 0.5s;
`
export const CardTitle = styled.div`
  font-weight: bolder;
  font-size: 20px;
  margin-top: 10px;
  padding: 10px;
  line-height: 1;
  box-shadow: 5px 5px 0.1px  gray ;
  background: darkslategrey;
  width: 350px;
  margin-left: 30px;
  border: 2px solid lightslategray;
`
export const CardTitle2 = styled.div`
  font-weight: bolder;
  font-size: 20px;
  margin-top: 10px;
  padding: 10px;
  line-height: 1;
  box-shadow: 5px 5px 0.1px  gray ;
  background: darkslategrey;
  width: 350px;
  margin-left: 30px;
  border: 2px solid lightslategray;
`



export const ContainerTodayCovidChartCSS = styled.div`
  margin-top: 30px;
  padding: 10px;
  border-radius: 20px;
  box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.3);
  transition: box-shadow 0.5s;
`
export const ContainerDonutLine = styled.div`
  margin-top: 100px;
`

export const HealthMainCSS = styled.div`

`
export const ClassCoinCSS = styled.div`

  margin: 5px;
  display: inline-grid;
  padding: 5px;
  grid-template-columns: auto auto;
  column-gap: 5px;
  row-gap: 2em;
  line-height: 30px;
`

export const FinChartCSS = styled.div`
  display: flex;
  flex-direction: column;
`

export const FinNewCSS = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 300px;
  padding: 05px;
`

export const HoverButtonFinNew = styled.div.attrs(() =>
    ({tabIndex: 0})
)`
  &:hover {
    cursor: pointer;
  }

`

export const SearchCSS = styled.div`
  display: flex;
  justify-content: left;
  padding: 5px;
  height: 30px;
  border-radius: 5px;
  white-space: pre-wrap;
  font-size: 12px;

`

export const SearchCSS1 = styled.div`
  display: flex;
  justify-content: left;
  margin-bottom: 50px;
  padding: 5px;
  height: 50px;
  border-radius: 5px;
  white-space: pre-wrap;
  font-size: 12px;

`

export const SearchCVWorld = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 25px;
  margin-bottom: 15px;
  height: 60px;
  white-space: pre-wrap;
`


export const ContainerPairCoinCSS1 = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  

`
export const PairCoinCSS = styled.div`
  display: flex;
  flex-direction: column;
  height: 100px;
  margin: 10px;
  padding: 10px;

`

export const HeaderTableCSS = styled.div`
  height: 80px;


`
export const MHeaderTableCSS = styled.div`
  height: 40px;
  margin-left: 10px;


`
export const ButtonTable = styled.div`
  height: 40px;
  width: 160px;
`

export const ButtonDouble = styled.div`
  height: 80px;
  line-height: 1;
  padding:10px;
  width: 160px;
`

export const ButtonTableMB = styled.div`
  height: 60px;
  width: 150px;
`


export const ContentPopUp = styled.div`
  height: 100px;
  width: 300px;
  box-shadow: 0px 10px 30px -5px rgba(0, 146, 146, 1);
  background: rgba(0, 80, 80,4);
  padding-bottom: 35px;
  padding-top: 05px;
  padding-left: 10px;

`


export const ButtonLager3 = styled.div`
  height: 40px;
  width: 130px;
`
export const ButtonLager2 = styled.div`
  height: 60px;
  width: 150px;
`
export const ButtonLager10 = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  width: 130px;
`
export const MarginButtonTable = styled.div`
  border: 0.1px solid gray;
  margin-left: 50px;
  width: 60px;
  font-size: 15px;
  padding: 5px;
  height: 20px ;
  border-radius: 10px;
  color: gray;
  background: ghostwhite;
`

export const MarginButtonTable2 = styled.div`
  margin-left: 50px;
  width: 60px;
  font-size: 15px;
  padding: 5px;
  border-radius: 10px;
`
export const ViewButton = styled.div`
  border: 0.1px solid gray;
  margin-left: 50px;
  width: 80px;
  font-size: 15px;
  padding: 5px;
  height: 20px ;
  border-radius: 10px;
  color: gray;
  background: ghostwhite;
`
export const MarginButtonTableMB = styled.div`
  margin-left: 50px;
  font-size: 15px;
  border-radius: 10px;
  color: white;
`

export const MarginButton330 = styled.div`
  font-size: 15px;
  margin-left: 20px;
  border-radius: 10px;
  color: white;
`
export const ButtonTopFin = styled.div`
  margin-left: 50px;
  width: 150px;
  height: 20px ;
  border-radius: 10px;
  color: white;
`

export const ButtonTopFinPopUp = styled.div`
  margin-left: 50px;
  width: 120px;
  height: 20px ;
  border-radius: 10px;
  color: white;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  cursor: pointer;
  position: relative;
`

export const ButtonTopFinPopUpDB = styled.div`
  margin-left: 50px;
  width: 120px;
  height: 40px ;
  border-radius: 10px;
  color: white;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  cursor: pointer;
  position: relative;
`
export const ButtonTopFinPopUpMB = styled.div`
  margin-left: 10px;
  width: 150px;
  height: 60px ;
  border-radius: 10px;
  color: white;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  cursor: pointer;
  position: relative;
`


export const ButtonTopFinPopUp2 = styled.div`
  height: 45px;
  width: 130px;
  border-radius: 10px;
  color: white;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  cursor: pointer;
  position: relative;
`
export const ButtonSingleCoin = styled.div`
  margin-left: 50px;
  width: 150px;
  font-size: 15px;
  padding: 5px;
  height: 20px ;
  border-radius: 10px;
  color: white;
`
export const ButtonSingleCoin2 = styled.div`
  margin-left: 15px;
 
`

export const MarginRight = styled.div`
margin-right: ${marginLeft.small};
`
export const Padding = styled.div`
  padding: 10px;
`
export const SizeofCell = styled.div`
  width: 200px;
  height: 30px;
`
export const MarginLeftTopBottom = styled.div`
  margin-left: 25px;
  margin-top: 60px;
  margin-bottom: 30px;
  padding-top: 50px;
  padding-left: 25px;
  
`

export const MarginTopBottom = styled.div`
  margin-bottom: 20px;
  margin-top: 20px
`

export const MarginChartInfo = styled.div`
  padding-bottom: 20px;
  color: inherit;
  background: inherit;
`
export const HomePageCSS = styled.div`
  display: flex;
  margin-left: 200px;
  padding-left: 100px;
  flex-direction: column;
`


export const MGroupHeaderTableCSS = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 0.5px solid gray;
  height: 60px;
  margin-top: 5px;
  justify-items: baseline;
`
export const MGroupHeaderTableCSS330= styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 0.5px solid gray;
  height: 60px;
  margin-top: 5px;
  justify-items: baseline;
`
export const PairsCoinCSS = styled.div`
  display: flex;
  flex-direction: row;
  overflow: hidden;
  height: 50px;
  padding: 5px;
  border:0.1px solid gray;
  margin-top: 5px;
  align-items: center;
  text-align: center;
`

export const MaxMinPairsCoinCSS = styled.div`
  display: flex;
  flex-direction: row;
  overflow: hidden;
  border-bottom: 0.5px solid #686868;
  height: 60px;
  margin-top: 15px;
  margin-bottom: 15px;
  align-items: baseline;
`
export const CandelProviderCSS = styled.div`
  margin-right: 10px;
`

export const LinkFinCoCSS = styled.div`
  margin-left: 130px;
  padding-left: 100px;
  background: #525151;
  border-radius: 2%;

`
export const TitleTopic = styled.div`
  font-size: 30px;
  font-weight: bolder;
  margin-left: 25px;

`
export const MsLinkFinCoCSS = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 40px;
`

export const MTtitleCSS = styled.div`
  margin-left: 60px;
  margin-right: 100px;
  height: 50px;
  display: flex;
  justify-content: center;
  border-radius: 10px;
  margin-bottom: 20px;
  margin-top: 20px;

`
export const ChartPeriodTabCSS = styled.div`
  border-radius: 2%;
  margin-left: 250px;
  color: #f37736;

`

export const EcoSpotCSS = styled.div`
  margin-left: 100px;
  padding: 60px;
  background: #525151;
  border-radius: 2%;

`

export const ImgFinCoCSS = styled.div`
  grid-area: 1   / 2 / span 2 / span 3;

`

export const ChildsEcoCSS = styled.div`
  display: flex;
  flex-direction: row;
`


export const ResourceComponentCSS = styled.div`
  paddingTop: 20px;
`

export const CCSBorder = styled.div`
    border: 0.1px solid gray;
`

export const BMICSSMobile = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  line-height: 30px;
  color: whitesmoke;
`
export const BMICSSMobile2 = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`
export const ContainerFormReset = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px;
`
export const ContainerFormReset2 = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  padding: 10px;
`
export const ContainerCovidVnTable = styled.div`
  display: flex;
  flex-direction: row;

`

