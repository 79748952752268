import React, {useContext} from 'react';

import {useTranslation} from "react-i18next";
import {SearchCVWorld} from "../../../../AppStyledMobile";
import {CovidWorldContext} from "../../../../../Provider/CovidProvider/CovidYesterday";

const SearchCVTODAY = () => {
    const {handleChange} = useContext(CovidWorldContext);
    const {t} = useTranslation();

    return (
        <div>
            <SearchCVWorld>
                <input
                    style={{background:"white", color:'black',  height:'30px',marginLeft :"20px",border: "1px solid gray" }}
                    type='text'
                    onChange={handleChange}
                    placeholder={t('  🔍 country')}
                    id='search'
                />
            </SearchCVWorld>

        </div>


    )

}
export default SearchCVTODAY;