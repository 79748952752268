
import styled, {keyframes} from "styled-components";


export const NavStyle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 70px;
  margin-left: 10px;
  height: 70px;
  align-items: center;
`
export const HoverThing = styled.div.attrs(() =>
    ({tabIndex: 0})
)`
  &:hover {
    color: darkgreen; // <Thing> when hovered
  }
`

export const ButtonStyle = styled.div`
  margin-left: 500px;
  margin-right: 30px;
  color: darkred;
  height: 40px;
  display: flex;
  align-items: baseline;
`

export const ButtonStyleNav = styled.div``
export const Input = styled.input`
  padding: 0.5em;
  margin: 0.5em;
  color: palevioletred;
  background: papayawhip;
  border: none;
  border-radius: 3px;
`;
export const HoverCard = styled.div.attrs(() =>
    ({tabIndex: 0})
)`
  &:hover {
    border-bottom: 1px solid dimgray;
  }
`

export const move = keyframes`
  from {
    left: 10px;
  }

  to {
    left: 200px;
  }
`;

export const Move = styled.div`

  animation: ${move} 100s infinite;
  position: relative;

`;
export const VNcovidCSSMB = styled.div`
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  
`
export const VNcovidCSS = styled.div`
  margin-left: 100px;
  padding-left: 10px;
  margin-top: 50px;
  display: flex;
  flex-direction: row;
  
  
`
export const HeaderCSS = styled.div`
  text-align: center;
  line-height: 30px;
  position: relative;
  background: #25383C;
  color:white;
`
export const ContainerLogoMenuTransParent = styled.div`
  box-sizing: border-box;
  position: relative;
  line-height: 1;
  visibility: visible;
  display: block;
  font:inherit;

`

export const ContainerLogoMenuTrans = styled.div`
  display: flex;
  flex-direction: row;
  justify-items: baseline;

`

export const LogoStyle = styled.div`
  position: absolute;
  margin-left: 50px;
  textDecoration: 'none';
  fontWeight: 'bold';
`
export const TranslateLap = styled.div`
  position: absolute;
  display: inline-block;
  margin-top: 10px;
  right: 30px;
`


export const ContainerRow = styled.div`
    display: flex;
  flex-direction: row;
 
`

export const ButtonCard = styled.div`
  text-align:center;
  width: 100px;
  height:20px;
  margin:5px;
  padding:8px;
  border-radius: 10px;
`

export const ButtonCardMobile = styled.div`
  text-align:center;
  width: 100px;
  height:20px;
  margin:5px;
  padding:8px;
  border-radius: 10px;
`


export const Button = styled.div`
  text-align:center;
  height:20px;
  border-radius: 10px;
`
export const TitleCard = styled.div`
  text-align:left;
  margin-left: 25px;
  padding:20px;
  border-radius: 10px;
`
export const TitleEcoSpot = styled.div`
  text-align:left;
  padding:10px;
  font-weight: bold;
  font-size: 20px;
`

export const HighLightContainer = styled.div`
  display: grid;
  grid-template-areas:
    'menu main main main right right'
    'menu main main main footer footer';
  gap: 30px;
  `

export const ItemMenu = styled.div`
  grid-area:menu;
  border: 0.1px solid gray;
  width:150px;
  text-align: center;
`
export const ItemMain = styled.div`
  grid-area:main;
  padding: 20px 0;
`
export const ItemRight = styled.div`
  grid-area:right;
  padding: 20px ;
  border: 0.1px solid gray;

`
export const ItemFooter = styled.div`
  grid-area:footer;
  padding: 20px ;
  border: 0.1px solid gray;

`

export const ButtonCss = styled.div`
  text-align:left;
  padding:10px;
  line-height: 1;
  border: 1px solid darkolivegreen;
`

export const ButtonSEE = styled.div`
  text-align:center;
  line-height: 1;
  cursor: pointer;
  height:25px;
  margin:20px;
  padding:10px;
  border-radius: 10px;
  border-right: 3px solid lightblue;
  border-left: 1px solid lightseagreen;
  border-top: 3px solid lightgreen;
  border-bottom: 1px solid lightseagreen;

`


export const MarginMain = styled.div`
    margin-top: 140px;
`

export const ButtonSEEMobile = styled.div`
  text-align:center;
  width: 100px;
  cursor: pointer;
  height:40px;
  margin:5px;
  padding:8px;
  border-radius: 10px;
  border-right: 3px solid lightblue;
  border-left: 1px solid lightseagreen;
  border-top: 3px solid lightgreen;
  border-bottom: 1px solid lightseagreen;

`
export const ButtonSEELarger = styled.div`
  text-align:center;
  cursor: pointer;
  border-radius: 5px;
  border-right: 3px solid lightblue;
  border-left: 1px solid lightseagreen;
  border-top: 3px solid lightgreen;
  border-bottom: 1px solid lightseagreen;

`
export const ButtonSEELarger2 = styled.div`
  text-align:center;
  padding:5px;
 

`