import React from "react";
import {
    Route,
    useRouteMatch, Switch,
} from "react-router-dom";

import {PageMobile} from "../../AppStyledMobile";
import Share from "./Share";

export const ECo = () => {
    let {path} = useRouteMatch();
    return (
        <PageMobile>

            <Switch>
                {/*for stock knowledge*/}
                <Route path={`${path}/stock`}/>
                {/*for spot new economy or stock */}
                <Route path={`${path}/high-light`} component={Share}/>
            </Switch>
        </PageMobile>

    )
}