import React from "react";
import { useTranslation } from "react-i18next";
import "../../../i18n"
import styled from 'styled-components';
import HomepageModel from "../../AppComponent/HomepageModel";
import {FullPage} from "../AppStyledLaptop";
import {Link} from "react-router-dom";
import Plot from "../../AppLaptop/Component/Homepage/ExportGOOD_ServiceAsiaT10CV.svg"


const Intro = styled.div`
  grid-area: header ;
  text-align: center;
  padding: 20px 0;
  border: 0.1px solid gray;


`
const StoryItem1 = styled.div`
  grid-area: menu;
  text-align: center;
  padding: 20px 0;
  width: 250px;
  border: 0.1px solid gray;
  white-space: pre-wrap;


`
const StoryItem2 = styled.div`
  grid-area: right;
  text-align: left;
  padding: 20px ;
  border: 0.1px solid gray;
  white-space: pre-wrap;

`
const GraIMG = styled.div`
  grid-area: main;
  border: 0.1px solid gray;
  padding: 10px;

`
const StoryItem5 = styled.div`
  grid-area: footer;
  text-align: left;
  padding: 20px ;
  border: 0.1px solid gray;
`
const ContainerHomePage = styled.div`
  display: grid;
  grid-template-areas:
    'header header header header header header'
    'menu main main main right right'
    'menu main main main footer footer';
  gap: 10px;
  padding: 10px;

`
const AboutDAStyled = styled.div`
  margin-top: 10px;
  padding: 20px;
  margin-bottom: 10px;
  color:white
 
`

const HomePage = ()=>{
    const {t} = useTranslation();
    return (
        <FullPage style ={{marginTop:"100px"}}>
            <ContainerHomePage >
                <Intro>
                    <p style={{fontSize:"20px"}}> "{t("Data reflects the past, the present, and the future orientation. Therefore data are stories of the life as well.")}" </p>
                </Intro>
                <StoryItem1>
                    <h3 style={{}}>
                        ️{t("The story of health")} </h3>
                </StoryItem1>
                <GraIMG>
                    <AboutDAStyled>
                        <HomepageModel/>
                    </AboutDAStyled>
                </GraIMG>
                <StoryItem2>
                    <p style={{}}>✍️{t("November 2019 SAR flu was detected and quickly became a global pandemic named COVID19."
                    )} </p>
                    <p style={{}}>{t("The pandemic is causing economic and human health losses.")} </p>
                </StoryItem2>
                <StoryItem5>
                    <Link to = "/health" style={{fontStyle:"italic",color:'inherit'
                    }}>{t(
                        "and more")} </Link>
                </StoryItem5>

            </ContainerHomePage>

            <ContainerHomePage>

                <StoryItem1>
                    <h3 style={{}}>
                        ️{t("The story of finance & economic")} </h3>
                </StoryItem1>
                <GraIMG>
                    <img className = "plotHP" src ={Plot} style={{width:"600px",  marginLeft:"20px", height:"600px"}}></img>

                </GraIMG>
                <StoryItem2>
                    <p style={{}}>{t("The impact of the COVID19 crisis on finance & economy")} </p>
                    <p style={{}}>{t("The value of exports of goods and services decreased during the 2019-2020 pandemic...")}
                    </p>
                </StoryItem2>
                <StoryItem5>
                    <Link to = "/eco/high-light" style={{fontStyle:"italic",color:'inherit', padding: "40px"
                    }}>{t(
                        "and more")} </Link>
                </StoryItem5>
            </ContainerHomePage>

        </FullPage>



    )
}
export default HomePage;