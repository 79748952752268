import './AppLaptop.css';
import React from "react";
import {BrowserRouter as Router, Route, Switch, useRouteMatch} from "react-router-dom";
import "../../i18n";
import "firebase/storage";
import BigHealthy from "./pages/Healthy/BigHealthy";
import VietnamCovid from "./pages/Healthy/CovidVN";
import HomePage from "./pages/HomePage";
import {LinksFinCo} from "./pages/Finance/Fin";
import {ContainerLogoMenuTrans, LogoStyle} from "../../AppStyled";
import TranslateDrop from "../AppComponent/Translate";
import InvestCRT from "./Component/Investment/InvestCRT";
import NavBarLaptop from "../AppComponent/NavBarLaptop";
import {ECo} from "./pages/Finance/Eco";
import DetailHistoryCoin from "./DetailHistoryCoin";

function AppLaptop() {
    return (
        <Router>
            <div>
                <ContainerLogoMenuTrans>
                    <NavBarLaptop/>
                    <LogoStyle>
                        <div style={{
                            textDecoration: 'none',
                            color: '#fe8a71',
                            fontSize: '48px',
                        }}>
                            <div><span style={{color: '#2ab7ca'}}>DtS</span>teller</div>
                        </div>
                    </LogoStyle>
                    <TranslateDrop> </TranslateDrop>
                </ContainerLogoMenuTrans>
            </div>


            <Switch>
                <Route path="/fin" component={LinksFinCo}/>
                <Route path="/share" component={ECo}/>
                <Route path="/coin/:symbol" component={DetailHistoryCoin}/>
                <Route path="/health" component={BigHealthy}/>
                <Route exact path="/" component={HomePage}/>
                <Route path="/investment" component={InvestCRT}/>
                <Route path="/CovidVN" component={VietnamCovid}/>
            </Switch>
        </Router>
    );
}
export default AppLaptop;
