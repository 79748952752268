import React from "react";


function DisplayBMI({ bmiValue }) {
    const status = (value) => {
        if (value < 18.5) return "Underweight";
        if (value >18.5 && value <=23) return "Healthy";
        if (value > 23 && value < 27.5) return " risk , need to lose 5% to 10% of weight ";
        else return "Obesity, need to lose 5% to 10% of weight";
    };

    return bmiValue ? (
        <section style={{marginLeft:'30px'}}>
            <h1>Your BMI : {bmiValue}</h1>
            {bmiValue > 23 && bmiValue <27.5 || bmiValue >27.5 || bmiValue<18.5?
                    (<p style={{color:"yellow", background:"black", textAlign:"center", marginRight:"35px"}}>{status(bmiValue)}</p>):
                    (<p style={{color:"green"}}> {status(bmiValue)}</p>) }
        </section>
    ) : (
        ""
    );
}

export default DisplayBMI;
