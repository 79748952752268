import React, { useState } from "react";
import {BMICSSMobile, BMICSSMobile2} from "../../../../AppLaptop/AppStyledLaptop";
import Form from "./Form";
import DisplayBMI from "./DisplayBMI";

function BMICheck() {
    const [height, setHeight] = useState(0);
    const [weight, setWeight] = useState(0);
    const [bmi, setBMI] = useState(0);

    const convertHeight = (e) => setHeight(e.target.value);

    const convertWeight = (e) => setWeight(e.target.value);

    const calculateBMI = (e) => {
        e.preventDefault();
        const calculate = Number(weight) / Math.pow(Number(height), 2);
        setBMI(Number(calculate).toFixed(2));
    };



    return (
        <BMICSSMobile2 style ={{color:"inherit"}}>
                <Form
                    weightValue={weight}
                    updateWeight={convertWeight}
                    heightValue={height}
                    updateHeight={convertHeight}
                    submitValues={calculateBMI}
                />

            <DisplayBMI bmiValue={bmi} />

        </BMICSSMobile2>
    );
}

export default BMICheck;
