import React from 'react';
import {v4 as uuidv4} from "uuid";
import {
    ButtonTop, ButtonTop2,
    CardTitle, CardTitle2, FullPage, LayoutRow,
    Padding,
    TitleLeft, TitleLeft2, TopCss, TopCss2,
} from "../../../../AppStyledLaptop";
import {useTranslation} from "react-i18next";
import "react-multi-carousel/lib/styles.css";
import {useSelector} from "react-redux";
import {VaccineCovidSelector} from "../../../../../redux-tooklit-app/VaccineCovidSlice";
import '../../../../../../App.css'
import {
    FacebookIcon,
    FacebookShareButton,
    TelegramIcon,
    TelegramShareButton,
    TwitterIcon,
    TwitterShareButton
} from "react-share";
import useWindowDimensions from "../../../../../../service/useWindowDimensions";

const FullTop = () => {
    const vaccineCovid = useSelector(VaccineCovidSelector)
    const [selectCon, setSelectCon] = React.useState("");
    const {t} = useTranslation();
    const width = useWindowDimensions()


    return (
        <FullPage style={{marginTop:'55px'}}>
            <Padding>
                <label htmlFor='continent'
                       style={{color: "inherit", fontWeight: "bolder"}}> COVID19-TOP10 </label>

                <select id='continent' style={{height: '30px'}} value={selectCon}
                        onChange={(e) => setSelectCon(e.currentTarget.value)}>
                    <option value=''>
                        All
                    </option>
                    {vaccineCovid.map(item => {
                        return (
                            <option value={item.Continent}>
                                {item.Continent}
                            </option>
                        )
                    })}

                </select>

                <LayoutRow>
                    <FacebookShareButton
                        url={"https://dts.pages.dev/"}
                        hashtag={["coin", "covid"]}
                    >
                        <FacebookIcon size={32} round />
                    </FacebookShareButton >
                    <TwitterShareButton
                        style = {{marginLeft:"10px"}}
                        url={"https://dts.pages.dev/"}
                        hashtags={["coin", "covid"]}
                    >
                        <TwitterIcon size={32} round />
                    </TwitterShareButton>
                    <TelegramShareButton
                        style = {{marginLeft:"10px"}}
                        url={"https://dts.pages.dev/"}
                        hashtags={["coin", "covid"]}
                    >
                        <TelegramIcon size={32} round />
                    </TelegramShareButton>
                </LayoutRow>
            </Padding>
            <Padding>
                {width>1000?(<div>
                    {vaccineCovid.filter(({Continent}) => selectCon ? Continent === selectCon : true).map(item => (
                        <div>
                            <LayoutRow>
                                <div>
                                    <CardTitle style={{color: "inherit"}}>{t("Cases")}<span
                                        style={{color: "gray", fontSize: "11px"}}>/{t("1M.per")}
                                                                   </span>
                                    </CardTitle>
                                    {item.Stat.flat().sort((a, b) => b.casesPerOneMillion - a.casesPerOneMillion).slice(0, 10).map(subItem =>
                                        (
                                            <div>
                                                <TopCss key={uuidv4()}>
                                                    <TitleLeft>{subItem.country}</TitleLeft>
                                                    <ButtonTop>{new Intl.NumberFormat('en').format(subItem.casesPerOneMillion)}</ButtonTop>
                                                </TopCss>
                                            </div>


                                        )
                                    )}
                                </div>


                                <div>
                                    <CardTitle style={{color: "inherit"}}>{t("Deaths")}
                                        <span
                                            style={{
                                                color: "gray",
                                                fontSize: "11px"
                                            }}>/{t("1M.per")}
                                                                </span>
                                    </CardTitle>
                                    {item.Stat.flat().sort((a, b) => b.deathsPerOneMillion - a.deathsPerOneMillion).slice(0, 10).map(subItem =>
                                        (
                                            <TopCss key={uuidv4()}>
                                                <TitleLeft>{subItem.country}</TitleLeft>
                                                <ButtonTop>{new Intl.NumberFormat('en').format(subItem.deathsPerOneMillion)}</ButtonTop>
                                            </TopCss>
                                        )
                                    )}
                                </div>

                                <div>
                                    <CardTitle style={{color: "inherit"}}>{t("Vaccine_Doses")}
                                        <span
                                            style={{color: "gray", fontSize: "11px"}}>/{t("1M.per")}
                                                                </span>
                                    </CardTitle>
                                    {item.Stat.flat().sort((a, b) => b.vaccinePerOneMillion - a.vaccinePerOneMillion).slice(0, 10).map(subItem =>
                                        (<TopCss key={uuidv4()}>
                                                <TitleLeft>{subItem.country}</TitleLeft>
                                                <ButtonTop>{new Intl.NumberFormat('en').format(subItem.vaccinePerOneMillion)}</ButtonTop>
                                            </TopCss>
                                        )
                                    )}
                                </div>

                            </LayoutRow>
                        </div>
                    ))}
                </div>):(<div >
                    {vaccineCovid.filter(({Continent}) => selectCon ? Continent === selectCon : true).map(item => (
                        <div>
                            <div>
                                <div>
                                    <CardTitle2 style={{color: "inherit"}}>{t("Cases")}<span
                                        style={{color: "gray", fontSize: "11px"}}>/{t("1M.per")}
                                                                   </span>
                                    </CardTitle2>
                                    {item.Stat.flat().sort((a, b) => b.casesPerOneMillion - a.casesPerOneMillion).slice(0, 10).map(subItem =>
                                        (
                                            <div>
                                                <TopCss2 key={uuidv4()}>
                                                    <TitleLeft2>{subItem.country}</TitleLeft2>
                                                    <ButtonTop>{new Intl.NumberFormat('en').format(subItem.casesPerOneMillion)}</ButtonTop>
                                                </TopCss2>
                                            </div>


                                        )
                                    )}
                                </div>


                                <div>
                                    <CardTitle2 style={{color: "inherit"}}>{t("Deaths")}
                                        <span
                                            style={{
                                                color: "gray",
                                                fontSize: "11px"
                                            }}>/{t("1M.per")}
                                                                </span>
                                    </CardTitle2>
                                    {item.Stat.flat().sort((a, b) => b.deathsPerOneMillion - a.deathsPerOneMillion).slice(0, 10).map(subItem =>
                                        (
                                            <TopCss2 key={uuidv4()}>
                                                <TitleLeft>{subItem.country}</TitleLeft>
                                                <ButtonTop2>{new Intl.NumberFormat('en').format(subItem.deathsPerOneMillion)}</ButtonTop2>
                                            </TopCss2>
                                        )
                                    )}
                                </div>

                                <div>
                                    <CardTitle2 style={{color: "inherit"}}>{t("Vaccine_Doses")}
                                        <span
                                            style={{color: "gray", fontSize: "11px"}}>/{t("1M.per")}
                                                                </span>
                                    </CardTitle2>
                                    {item.Stat.flat().sort((a, b) => b.vaccinePerOneMillion - a.vaccinePerOneMillion).slice(0, 10).map(subItem =>
                                        (<TopCss2 key={uuidv4()}>
                                                <TitleLeft2>{subItem.country}</TitleLeft2>
                                                <ButtonTop2>{new Intl.NumberFormat('en').format(subItem.vaccinePerOneMillion)}</ButtonTop2>
                                            </TopCss2>
                                        )
                                    )}
                                </div>

                            </div>
                        </div>
                    ))}
                </div>)}


            </Padding>
        </FullPage>
    )
}
export default FullTop;