import React, {useContext} from "react";
import {
    ButtonTable, ButtonTopFin, ButtonTopFinPopUp, ButtonTopFinPopUpDB,
    CardTopSwitch, CardTopSwitch2, ContentPopUp, LayoutColumn,
    MarginLeftRight,
    MaxMinPairsCoinCSS,
    MGroupHeaderTableCSS,
    MTtitleCSS,
} from "../../../AppStyledLaptop";
import {useTranslation} from "react-i18next";
import {v4 as uuid} from "uuid";
import Infinite from "react-infinite";
import {Link} from "react-router-dom";
import {CandleContext2} from "../../../../Provider/CandleProvider2";
import Popup from "reactjs-popup";
import '../../../AppLaptop.css'
const MaxMin24Column = () => {
    const {t} = useTranslation();
    const {coin24, colorBG} = useContext(CandleContext2)
    const MaxVolume = coin24.slice(0, 1961).sort(function (a, b) {
        return parseFloat(b.volume) - parseFloat(a.volume);
    });
    const MaxTrader = coin24.sort(function (a, b) {
        return parseFloat(b.count) - parseFloat(a.count);
    });

    return (

                <LayoutColumn>
                    <CardTopSwitch2 style={{background: `${colorBG.backgroundColor}`, color: `${colorBG.color}`}}>
                        <MarginLeftRight>
                            <MTtitleCSS><h3>{t("Top 10 Volume SpotMarket 24h")}</h3></MTtitleCSS>
                            <MGroupHeaderTableCSS>
                                <ButtonTable>
                                        <Popup trigger={() => (
                                        <ButtonTopFinPopUpDB style ={{ marginLeft: '0px',paddingLeft:'5px'}} className="button">
                                            <strong style={{}}>{t("Pair")} <em style={{fontSize: "11px"}}></em></strong>
                                            <br/>
                                            <strong style={{}}>{t("PriceChange")} <em style={{fontSize: "11px"}}>%</em></strong>
                                        </ButtonTopFinPopUpDB>
                                    )}
                                           position="right center"
                                           closeOnDocumentClick

                                    >
                                        <ContentPopUp>
                                            <p style={{whiteSpace:'pre-wrap'}}>{t('base asset/quote asset')} </p>
                                            <Link to ='/share'>{t("and more")}</Link>
                                        </ContentPopUp>

                                    </Popup>

                                </ButtonTable>
                                <ButtonTable>
                                    <Popup trigger={() => (
                                        <ButtonTopFinPopUp  className="button">
                                            <strong style={{  padding:"10px"
                                            }}>{t("Volume")}<em
                                                style={{fontSize: "11px"}}>($)</em></strong>
                                        </ButtonTopFinPopUp>
                                    )}
                                           position="right center"
                                           closeOnDocumentClick

                                    >
                                        <ContentPopUp>
                                            <p style={{whiteSpace:'pre-wrap'}}>{t('Higher trading volumes are considered more positive than lower trading volumes because they mean more liquidity and better order execution')}</p>
                                            <Link to ='/share'>{t("and more")}</Link>
                                        </ContentPopUp>

                                    </Popup>
                                </ButtonTable>

                                <ButtonTable style ={{marginLeft:'50px'}}>
                                    <Popup trigger={() => (
                                        <ButtonTopFinPopUp  className="button">
                                            <strong style={{  padding:"5px"
                                            }}>{t("VWAP")} <em
                                                style={{fontSize: "11px"}}>($)</em></strong>
                                        </ButtonTopFinPopUp>
                                    )}
                                           position="bottom right"
                                           closeOnDocumentClick

                                    >
                                        <ContentPopUp>
                                            <p>
                                                {t('Popup content')}
                                            </p>
                                            <Link to ='/share'>{t("and more")}</Link>
                                        </ContentPopUp>

                                    </Popup>
                                </ButtonTable>
                            </MGroupHeaderTableCSS>
                            <Infinite containerHeight={300}
                                      elementHeight={80}>
                                {MaxVolume.slice(0, 10).map((item) =>
                                    (
                                        <MaxMinPairsCoinCSS>
                                            <ButtonTable key={uuid()}>
                                                <Link style={{
                                                    textAlign: "center", color: `${colorBG.color}`,
                                                    background: `${colorBG.backgroundColor}`, textDecoration: "none"
                                                }}
                                                      to='/fin/crypto'>
                                                    {item.symbol}
                                                </Link>
                                                <br/>
                                                <div>{item.priceChangePercent}</div>
                                            </ButtonTable>
                                            <ButtonTopFin>
                                                <ButtonTable key={uuid()}>
                                                    {new Intl.NumberFormat('en-US', {style: 'decimal'}).format(item.volume)}
                                                </ButtonTable>
                                            </ButtonTopFin>
                                            <ButtonTopFin>
                                                <ButtonTable key={uuid()}>
                                                    {parseFloat(item.weightedAvgPrice)}
                                                </ButtonTable>
                                            </ButtonTopFin>
                                        </MaxMinPairsCoinCSS>
                                    ))}
                            </Infinite>
                        </MarginLeftRight>
                    </CardTopSwitch2>

                    <CardTopSwitch2
                        style={{background: `${colorBG.backgroundColor}`, color: `${colorBG.color}`}}>
                        <MarginLeftRight>
                            <MTtitleCSS><h3>{t("Top 10 Trader SpotMarket 24h")}</h3></MTtitleCSS>
                            <MGroupHeaderTableCSS>
                                <ButtonTable>
                                    <strong>{t("Pair")} <em style={{fontSize: "11px"}}></em></strong>
                                    <br/>
                                    <strong>{t("PriceChange")} <em style={{fontSize: "11px"}}>%</em></strong>
                                </ButtonTable>

                                <ButtonTable>
                                    <Popup trigger={() => (
                                        <ButtonTopFinPopUp  className="button">
                                            <strong>{t("Traders")} </strong>
                                            <em style={{fontSize: "11px"}}>(person)</em>
                                        </ButtonTopFinPopUp>
                                    )}
                                           position="right center"
                                           closeOnDocumentClick

                                    >
                                        <ContentPopUp>
                                            <p style={{whiteSpace:'pre-wrap'}}>{t('Higher trading volumes are considered more positive than lower trading volumes because they mean more liquidity and better order execution')}</p>
                                            <Link to ='/share'>{t("and more")}</Link>
                                        </ContentPopUp>

                                    </Popup>
                                </ButtonTable>

                                <ButtonTable>
                                    <ButtonTopFin>
                                        <strong style={{}}>{t("VWAP")} <em
                                            style={{fontSize: "11px"}}>($)</em></strong>
                                    </ButtonTopFin>
                                </ButtonTable>


                            </MGroupHeaderTableCSS>

                            <Infinite containerHeight={300}
                                      elementHeight={80}>
                                {MaxTrader.slice(0, 10).map((item) =>
                                    (
                                        <MaxMinPairsCoinCSS>
                                            <ButtonTable key={uuid()}>
                                                <Link style={{
                                                    textAlign: "center", color: `${colorBG.color}`,
                                                    background: `${colorBG.backgroundColor}`, textDecoration: "none"
                                                }}
                                                      to='/fin/crypto'>
                                                    {item.symbol}
                                                </Link>
                                                <br/>
                                                <div>{item.priceChangePercent}</div>
                                            </ButtonTable>


                                            <ButtonTopFin>
                                                <ButtonTable key={uuid()}>
                                                    <div key={uuid()}>
                                                        {new Intl.NumberFormat('en-US', {style: 'decimal'}).format(item.count)}
                                                    </div>
                                                </ButtonTable>
                                            </ButtonTopFin>
                                            <ButtonTopFin>
                                                <ButtonTable key={uuid()}>
                                                    {parseFloat(item.weightedAvgPrice)}
                                                </ButtonTable>
                                            </ButtonTopFin>
                                        </MaxMinPairsCoinCSS>
                                    ))}
                            </Infinite>
                        </MarginLeftRight>
                    </CardTopSwitch2>
                </LayoutColumn>
            )

};

export default MaxMin24Column;