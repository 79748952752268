import React, { useState} from 'react';


const useFilter =  ((CoinsData) => {
    const [search, setSearch] = useState('')
    const handleChange = (e) => {
        setSearch(e.target.value)
    }

    const filteredCoin = React.useMemo(
        () =>
            CoinsData.filter((dt) => {
                return dt.symbol.toLowerCase().includes(search.toLowerCase());
            }),
            [search,CoinsData]
    );


return {handleChange,filteredCoin}
} )
export default useFilter;



