
export const SidebarData = [
    {
        title: "Home",
        path: "/",
        cName: "nav-text"
    },
    {
        title: "Health",
        path: "/health",
        cName: "nav-text"
    },
    {
        title: "Finance",
        path: "/fin",
        cName: "nav-text"
    },
    {
        title: "HighLight",
        path: "/share/high-light",
        cName: "nav-text"
    },


];
