import {defaultStyles} from "@visx/tooltip";
import {scaleOrdinal} from "@visx/scale";



export const dimensions = {
    width: 600,
    height: 300,
    margin: {
        top: 30,
        right: 30,
        bottom: 30,
        left: 60
    }
};


export const red = "#fe4a49";
export const grey = "#808080";
export const green = "#2ab7ca";
export const yellow = "#ffd32e";
// const lightYellow = "#ffcd00";
// const lightestYellow = "#ffe57c";
// const lightGray = "#ccd3de";
export const brown = "#f37736";
export const darkGray = "#854442";
export const background = "#f4f4f8";
export const defaultMargin = { top: 100, right: 0, bottom: 100, left: 350 };
export const tooltipStyles = {
    ...defaultStyles,
    minWidth: 60,
    minHeight: 60   ,
    backgroundColor: "#4b86b4",
    color: "#f6cd61"
};

export const legendStyles = {
    display: "flex",
    minWidth: 230,
    backgroundColor: "#AF601",
    color: "#f37736",
    fontSize: 12,
    top: 50 ,
    left: 10,
};


export const keys = [
  "cases",
    "deaths",
    "todayCases"

];

export const colorScale = scaleOrdinal({
    domain: keys,
    range: [
        red,
        grey,
        green,
        yellow,
        yellow,
        yellow,
        brown,
        darkGray
    ]
});

export const width = 900;
export const height = 500
//for images
export const manner = {
    autoSliding: {interval: "4s"}
    , duration: "0.3s"
};




export const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 9
    },
    desktop: {
        breakpoint: { max: 2999, min: 1100 },
        items: 7
    },
    desktop02: {
        breakpoint: { max: 1023, min: 1089 },
        items: 3
    },
    tablet: {
        breakpoint: { max: 1088, min: 913 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 912, min: 320 },
        items: 1
    }
};