import React from 'react';
import {SearchCVWorld} from "../../../../AppStyledLaptop";

import {useTranslation} from "react-i18next";

const SearchCVTODAY = (props) => {
    const handleChange= props.handleChange
    const {t} = useTranslation();

    return (
        <div>
            <SearchCVWorld>
                <input
                    style={{background:"white",  height:'30px',marginLeft :"20px",color:"black",border: "1px solid gray" }}
                    type='text'
                    onChange={handleChange}
                    placeholder={t('  🔍 Search country')}
                    id='search'
                />

            </SearchCVWorld>

        </div>


    )

}
export default SearchCVTODAY;