import styled from "styled-components";

export const CardStyledMobile = styled.div`
  display: flex;
`

export const ButtonSymbol = styled.div`
  text-align: center;
  height: 40px;
  width: 100px;

`;

export const SpanButtonSymbol = styled.div`
  padding-left: 5px;
`;

export const LinksFinCSS = styled.div`
  display: flex;
  flex-direction: row;

  &:hover {
    background-color: #e3f0ff;
  }
`

export const GroupCardStyledMobile = styled.div`
  display: grid;
  grid-template-columns: auto auto;
`
export const CoinIntroStyled = styled.div`
  margin-left: 40px;
`
export const ButtonTopicStyled = styled.div`
  font-weight: bolder;
  height: 10px;
  padding: 05px;
`
export const ResponStyle = styled.div`
  display: flex;
  flex-direction: row`
export const ButtonTableCSS = styled.div`
  margin: 10px;
  padding: 10px;
  line-height: 100px
`

export const Covid30CSS = styled.div`
  display: flex;
  align-items: baseline;
  flex-direction: column;
  border: 1px solid lightgray;
  margin: 5px;
  border-radius: 10px;
`
export const ClassRateCSS = styled.div`
  margin-top: 5px;
  display: inline-grid;
  padding: 10px;
  grid-template-columns: auto auto;
  column-gap: 5px;
  row-gap: 2em;
`

export const CovidRateCSS = styled.div`
  height: 200px;
  width: 280px;
  padding: 30px;
  display: flex;
  align-items: center;
  flex-direction: column;
  border: 1px solid whitesmoke;
  border-radius: 20px;
  background: #e6e6ea;
`

export const ClassCoinCSS = styled.div`
  margin: 5px;
  display: inline-grid;
  padding: 5px;
  grid-template-columns: auto auto;
  column-gap: 5px;
  row-gap: 2em;
  line-height: 30px;
`
export const FinChartCSS = styled.div`
  display: flex;
  flex-direction: column;
`
export const FinNewCSS = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 300px;
  padding: 05px;
`
export const HoverButtonFinNew = styled.div.attrs(() =>
    ({tabIndex: 0})
)`
  &:hover {
    color: #fe4a49; // <Thing> when hovered
  }
`

export const PlotHOMEPAGECSS = styled.div`
  white-space: pre-wrap;
`

export const SearchCSS = styled.div`
  display: flex;
  justify-content: left;
  margin-right: 5px;
  margin-left: 20px;
  padding: 5px;
  height: 22px;
  white-space: pre-wrap;
  font-size: 12px;
`
export const SearchCSS2 = styled.div`
  display: flex;
  justify-content: left;
  margin-right: 5px;
  margin-left: 20px;
  padding: 5px;
  white-space: pre-wrap;
  font-size: 12px;
`


export const SearchCVWorld = styled.div`
  display: flex;
  justify-content: left;
  margin-right: 5px;
  margin-bottom: 15px;
  padding: 5px;
  height: 40px;
  width: 200px;
  white-space: pre-wrap;
  font-size: 12px;
`

export const ContainerPairCoinCSS = styled.div`
  margin-top: 20px;
`

export const ContainerSearchTab = styled.div`
  margin-left: 10px;
`
export const PairCoinCSS = styled.div`
  display: flex;
  flex-direction: column;
  height: 100px;
  width: 100px;
  margin: 10px;
  padding: 05px;
`

export const HeaderTableCSS = styled.div`
  height: 80px;
  font-size: 19px;
  width: 120px;
  padding-right: 5px;
`
export const MHeaderTableCSS = styled.div`
  height: 80px;
  margin-left: 50px;
`

export const HomePageCSS = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 150px;
`


export const ContainerButtonTable = styled.div`
  display: flex;
  flex-direction: row;
  overflow: hidden;
  justify-items: baseline;
`

export const MGroupHeaderTableCSS = styled.div`
  display: flex;
  flex-direction: row;
  overflow: hidden;
  border-bottom: 0.5px solid white;
  //height: 80px;
  margin-top: 5px;
  justify-items: baseline;
`
export const MGroupHeaderTableCSS330 = styled.div`
  display: flex;
  flex-direction: row;
  overflow: hidden;
  border-bottom: 0.5px solid white;
  height: 80px;
  margin-top: 5px;
  justify-items: baseline;
`
export const PairsCoinCSS = styled.div`
  display: flex;
  flex-direction: row;
  overflow: hidden;
  border-bottom: 0.5px solid #525151;
  height: 80px;
  font-size: 18px;
  margin-top: 5px;
  align-items: baseline;
  text-align: center;
`

export const CandelProviderCSS = styled.div`
  margin-right: 10px;
`
export const LayoutSpotMCSS = styled.div`
  font-size: 20px;
`


export const ContainerPage = styled.div`
  margin-left: 10px;
  margin-top: 80px;
  font-family: "Times New Roman";
  font-size: 18px;


`
export const EcoSpotCSS = styled.div`
  padding-left: 5px;
  font-size: 20px;
  border-radius: 2%;
`

export const MaxStasCSS = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px
`



export const MTtitleCSS = styled.div`
  margin-left: 20px;
  height: 50px;
  display: flex;
  justify-content: center;
  border-radius: 10px;
  margin-bottom: 20px;
  margin-top: 20px;
`
export const MTtitleCSS330 = styled.div`
  margin-left: 20px;
  height: 40px;
  display: flex;
  justify-content: left;
  border-radius: 10px;
  margin-bottom: 20px;
  margin-top: 20px;
`

export const ChartPeriodTabCSS = styled.div`
  border-radius: 2%;
`

export const ChildsEcoCSS = styled.div`
  display: flex;
  flex-direction: row;
`
export const ResourceComponentCSS = styled.div`
  padding: 20px;
`

export const PageMobile = styled.div`
  top: 200px;
  font-family: "Times New Roman";
  font-size: 18px;


`