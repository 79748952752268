import React, {createContext, useState} from "react";
import useMarket24 from "../hooks/useMarket24hr";


export const CandleContext2= createContext(null);

export const CandleProvider2 = props => {
    const [colorBG, setColorBG] = useState({backgroundColor: "#033E3E", color:"white"})
    document.body.style.backgroundColor = colorBG.backgroundColor;
    document.body.style.color = colorBG.color;
    const {coin24} = useMarket24()

    return (
        <CandleContext2.Provider value={{coin24,colorBG, setColorBG,

        }}>
            {props.children}
        </CandleContext2.Provider>
    );
}


