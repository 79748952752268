import React from "react";
import {ContainerFormReset, MarginButtonTable} from "../../../AppStyledLaptop";
import {useTranslation} from "react-i18next";

function Form({
                  weightValue,
                  updateWeight,
                  heightValue,
                  updateHeight,
                  reCal,
                  submitValues
              }) {
    const {t} = useTranslation()
    return (
        <div>
            <div>
                <h3> {t("Check IBM index")} ? </h3>
                <div>
                    <div>
                        <div>
                            <label  htmlFor="weight">{t("Weight")} (kg)</label>
                            <input id="weight" value={weightValue} onChange={updateWeight}/>
                        </div>
                        <div>
                            <label  htmlFor="height">{t("Height")} (m)</label>
                            <input  id="height" value={heightValue} onChange={updateHeight}/>
                        </div>
                    </div>
                </div>
                <div>
                    <ContainerFormReset>
                        <button  disabled={!weightValue && !heightValue} onClick={submitValues}>
                            {t("Calculate")}
                        </button>
                        <button  onClick={reCal}>{t("Reset")}</button>
                    </ContainerFormReset>
                </div>
            </div>
        </div>
    );
}

export default Form;
