import React from "react";
import {GraphicMB} from "./TabCoin/GraphicComMobile/Graphic";
import {Graphic14MB} from "./TabCoin/GraphicComMobile/Graphic14";
import {Graphic50MB} from "./TabCoin/GraphicComMobile/Graphic50";
import {Graphic100MB} from "./TabCoin/GraphicComMobile/Graphic100";
import {Graphic200MB} from "./TabCoin/GraphicComMobile/Graphic200";
import {Graphic300MB} from "./TabCoin/GraphicComMobile/Graphic300";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {CoinsBinSelector} from "../../../redux-tooklit-app/coinsSlice";


const ChartPeriodsMobile = () => {
    const [selectPeriod, setSelectPeriod] = React.useState("")
    const coinBin = useSelector(CoinsBinSelector)
    const CandleChart = Object.values(coinBin).flat()

    const renderPeriod = () => {
        if (selectPeriod && selectPeriod === '300days') {
            return (<div>
                <Graphic300MB CandleChart ={CandleChart}/>
            </div>)
        } else if (selectPeriod && selectPeriod === '100days') {

            return (<div>

                <Graphic100MB CandleChart ={CandleChart}/>
            </div>)
        } else if (selectPeriod && selectPeriod === '50days') {
            return (<div>
                <Graphic50MB CandleChart ={CandleChart}/>
            </div>)

        } else if (selectPeriod && selectPeriod === '14days') {
            return (<div>
                <Graphic14MB CandleChart ={CandleChart}/>
            </div>)


        } else if (selectPeriod && selectPeriod === '200days') {
            return (<div>
                <Graphic200MB CandleChart ={CandleChart}/>
            </div>)


        } else {
            return (<div>
                <GraphicMB CandleChart ={CandleChart}/>

            </div>)
        }

    }
    const {t} = useTranslation();

    return (
        <div style={{marginTop: "0px"}}>
            <i style={{marginLeft: "20px"}}>{t("Choose Periods")}:</i>
            <select style={{marginLeft: "20px", width: '200px', height: '40px'}}
                    onChange={(e) => setSelectPeriod(e.currentTarget.value)} value={selectPeriod}>
                <option value=''>
                    -
                </option>
                <option value='300days'>
                    {t("300days")}
                </option>
                <option value='200days'>
                    {t("200days")}
                </option>
                <option value='100days'>
                    {t("100days")}
                </option>
                <option value='50days'>
                    {t("50days")}
                </option>
                <option value='14days'>
                    {t("14days")}
                </option>
            </select>

            {renderPeriod()}

        </div>
    )
}
export default ChartPeriodsMobile;