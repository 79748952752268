import React from "react";
import {useList} from "react-firebase-hooks/database";
import {
    ButtonTopicStyled,
    ContainerPairCoinCSS,
    EcoSpotCSS,
} from "../../AppStyledMobile";
import {useTranslation} from "react-i18next";
import {TitleCard} from "../../../../AppStyled";
import {getAll} from "../../../../service/EconomicDatabase";

const HighLight = React.memo(() => {
    const {t} = useTranslation();
    const [finnews, loading, error] = useList(getAll());

    return (
        <div style={{paddingLeft:'20px'}}>
            {error && <strong>Error: {error}</strong>}
            {loading && <span style={{color: "darkGray"}}>✨...</span>}
            <div>
                {!loading &&
                    finnews &&
                    finnews.map((finnew, index) => (
                        <EcoSpotCSS>
                            <ButtonTopicStyled>
                                <TitleCard
                                    key={index}>
                                    {t(finnew.val().name)}
                                </TitleCard>

                            </ButtonTopicStyled>
                            <br/>
                            <ContainerPairCoinCSS>
                                <p style={{whiteSpace: "pre-wrap", border: "none"}}>{t(finnew.val().content)}</p>
                                <p style={{whiteSpace: "pre-wrap", border: "none"}}>{finnew.val().description1}</p>
                                <img src={finnew.val().url}
                                     style={{width:'327px',paddingLeft:'100px'}} alt={finnew.val().url}/>
                            </ContainerPairCoinCSS>
                        </EcoSpotCSS>

                    ))}
            </div>
            <br/>

        </div>
    );
});

export default HighLight;
