import React, {useContext} from 'react';

import SearchCVTODAY from "./SearchCVTODAY";
import {CovidWorldContext} from "../../../../../Provider/CovidProvider/CovidYesterday";
import {useTranslation} from "react-i18next";
import {
    FullPage, LayoutRow,
    MarginLeft2
} from "../../../../../AppLaptop/AppStyledLaptop";
import {ButtonSEE, TitleCard} from "../../../../../../AppStyled";
import Fluctuation90d from "../../../../pages/Healthy/30d/FluctuationCovid90d";
import {CandleContext2} from "../../../../../Provider/CandleProvider2";

const EntireWorld=()=>{
    const {filteredCountry,cardComponent3,setVisibleList,seeList} =useContext(CovidWorldContext)
    const {t} = useTranslation();
    const {colorBG} = useContext(CandleContext2)


    return(
        <div style={{marginTop:'50px'}}>
                    <FullPage>
                        <h2 style={{padding:'10px'}} >
                            {t("Today")}<span><em
                            style={{fontSize: "10px"}}>[ref:https://www.worldometers.info]</em></span>
                        </h2>
                        <SearchCVTODAY/>
                        <MarginLeft2 style={{
                            position:'relative',

                        }}>

                            <FullPage style={{}} >
                                <LayoutRow  style={{border:"1px solid gray", width:"360px", background:colorBG.backgroundColor}}>
                                    <TitleCard style={{fontWeight: "bold", width: '150px'}}>{t("Country")}</TitleCard>
                                    <TitleCard style={{fontWeight: "bold",marginLeft:'20px'}}>{t("Cases")}</TitleCard>
                                </LayoutRow>
                                {cardComponent3}
                                <LayoutRow>
                                    <ButtonSEE style={{
                                        color: `${colorBG.color}`,
                                        margin: "10px",
                                        background: `${colorBG.backgroundColor}`
                                    }}
                                               disabled={filteredCountry.length === cardComponent3.length}
                                               type="button"
                                               onClick={() => setVisibleList((prev) => prev + 100)}
                                    >
                                        {t("see more")}
                                    </ButtonSEE>
                                    {seeList && cardComponent3}
                                    <ButtonSEE style={{
                                        color: `${colorBG.color}`,
                                        margin: "10px",
                                        background: `${colorBG.backgroundColor}`
                                    }}
                                               visable={cardComponent3.length === 5}
                                               type="button"
                                               onClick={() => setVisibleList(5)}
                                    >
                                        {t("see less")}
                                    </ButtonSEE>
                                    {seeList && cardComponent3}
                                </LayoutRow>
                            </FullPage>
                        </MarginLeft2>
                    </FullPage>
            <br/>
            <FullPage>
                <Fluctuation90d/>
            </FullPage>
            <br/>

            <br/>
            <FullPage >

            </FullPage>
        </div>

    )

}
export default EntireWorld;