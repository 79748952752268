import React, {useState} from "react";
import {useSelector} from 'react-redux'
import {v4 as uuidv4} from 'uuid';
import {useTranslation} from "react-i18next";
import SearchCountry from "../../../../../AppMobile/Component/Health/Covid/CovidComponent/SearchCountry/SearchCountry";
import {FullPage, GridContainer2, LayoutRow, Padding} from "../../../../AppStyledLaptop";
import TopCom from "./30d/RankCom";
import {Covid90Selector} from "../../../../../redux-tooklit-app/Covid90d";
import {
    FacebookIcon,
    FacebookShareButton,
    TelegramIcon,
    TelegramShareButton,
    TwitterIcon,
    TwitterShareButton
} from "react-share";

const Fluctuation90FULL = () => {
    const covid90d = useSelector(Covid90Selector)
    const [selectCon, setSelectCon] = React.useState("");
    const {t} = useTranslation()
    const [search, setSearch] = useState('')

    const handleChange = (e) => {
        setSearch(e.target.value);
    };
    return (
        <FullPage>
            <div>
                <LayoutRow >
                    <Padding>
                        <label htmlFor="select">
                            <strong>{t("COVID19-FLUCTUATION")}: </strong>
                        </label>
                        <select id='select' style={{height: '30px'}} value={selectCon}
                                onChange={(e) => setSelectCon(e.currentTarget.value)}>
                            <option value=''>
                                All
                            </option>
                            {covid90d.map(item => {
                                return (
                                    <option value={item.Continent}>
                                        {item.Continent}
                                    </option>
                                )
                            })}

                        </select>

                        <LayoutRow>
                            <FacebookShareButton
                                url={"https://dts.pages.dev/"}
                                hashtag={["coin", "covid"]}
                            >
                                <FacebookIcon size={32} round />
                            </FacebookShareButton>
                            <TwitterShareButton
                                style = {{marginLeft:"10px"}}
                                url={"https://dts.pages.dev/"}
                                hashtags={["coin", "covid"]}
                            >
                                <TwitterIcon size={32} round />
                            </TwitterShareButton>
                            <TelegramShareButton
                                style = {{marginLeft:"10px"}}
                                url={"https://dts.pages.dev/"}
                                hashtags={["coin", "covid"]}
                            >
                                <TelegramIcon size={32} round />
                            </TelegramShareButton>
                        </LayoutRow>
                    </Padding>
                    <SearchCountry style={{margin:'70px',padding:'70px'}} handleChangeCountry ={handleChange}/>
                </LayoutRow>
            </div>
            <div style={{padding:'15px'}}>
             <div>
                    {covid90d.filter(({Continent}) => selectCon ? Continent === selectCon : true).map(item => {
                        return (
                            <GridContainer2 key={uuidv4()}>
                                {item.Stat[0].data.filter((subItem) => {
                                    return subItem.country.toLowerCase().includes(search.toLowerCase());
                                }).map(filteredCountry90 => (
                                    <div key={uuidv4()}>
                                        <TopCom GraphicData={filteredCountry90}></TopCom>
                                        <br/>
                                    </div>
                                ))}
                            </GridContainer2>

                        )
                    })}
                </div>




            </div>
        </FullPage>

    );
}
export default Fluctuation90FULL;
