import React from "react";
import {
    FullPage,
} from "../../AppStyledLaptop";
import {useSelector} from "react-redux";
import {Coins24Selector} from "../../../redux-tooklit-app/coinsSlice";
import Crypto24hr from "../../Crypto24hr";
import useFilter from "../../../hooks/useFilter";
import SearchCom from "../../Component/Finance/SearchCoin/SearchCom";

const FinanceCRT = () => {
    const coins24 = useSelector(Coins24Selector)
    const {handleChange: handleChangeETF, filteredCoin: SearchCoin24s} = useFilter(coins24)

    return (
        <div>
            <FullPage style ={{marginTop:'100px'}}>
                <div>
                    <SearchCom handleChangeCoin ={handleChangeETF} />
                    <Crypto24hr TabData={SearchCoin24s}/>
                </div>
            </FullPage>
            <br/> <br/>

        </div>



    )
}
export default FinanceCRT;