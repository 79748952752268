import React from "react";
import {TinyLineChart} from "../ChartCom/TinyLineChart";
import {
    MarginLeftFluc
} from "../../../../../../AppLaptop/AppStyledLaptop";
import {useTranslation} from "react-i18next";


const TopCom = React.memo((props) => {
    let Item = props.GraphicData;
    const {t} = useTranslation();
    return (
        <div style={{width: '400px'}}>
            <TinyLineChart data={Item.Covid30days}/>
            <div style={{border: "0.1px solid gray", display: "flex", width: '400px', justifyContent: "center"}}>
                <h4>{Item.country}</h4>
                <MarginLeftFluc>
                    <div>{Item.balance > 0 ? (<div>
                        {Item.balance > 50 ? (
                            <p style={{color: 'red'}}>
                                {'+' + `${Number.parseFloat(Item.balance).toFixed(2)}` + "% " + t('High Risk')}
                            </p>

                        ) : (
                            <p style={{color: ''}}>
                                {'+' + `${Number.parseFloat(Item.balance).toFixed(2)}` + "% " + t('Low Risk')}
                            </p>
                        )} </div>) : ('-' + `${Number.parseFloat(Item.balance).toFixed(2)}` + "%")}</div>
                </MarginLeftFluc>
            </div>
        </div>
    );
});

export default TopCom;