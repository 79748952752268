import React, {useContext, useState} from "react";
import { IconContext } from "react-icons";
import {Link} from "react-router-dom";
import * as FaIcons from "react-icons/fa"; //Now i get access to all the icons
import '../AppLaptop/NavBarLaptop.css'
import'../../App.css'
import {useTranslation} from "react-i18next";
import * as AiIcons from "react-icons/ai";
import {SidebarData} from "./SidebarData";
import {CandleContext2} from "../Provider/CandleProvider2";
import '../AppMobile/NavBarMb.css'

function NavBarLaptop() {
    const {colorBG} = useContext(CandleContext2)
    const [sidebar, setSidebar] = useState(false);
    const showSidebar = () => setSidebar(!sidebar);
    const {t} = useTranslation();


    return (
        <div>
        <IconContext.Provider value={{color: `${colorBG.color}`}}>
            {/* All the icons now are white */}
            <div className="navbar" style ={{ backgroundColor: `${colorBG.backgroundColor}`,color: `${colorBG.color}`}}>
                <Link to="#" className="menu-bars">
                    <FaIcons.FaBars onClick={showSidebar}/>
                </Link>
            </div>
            <nav className={sidebar ? "nav-menu active" : "nav-menu"} style ={{ backgroundColor: `${colorBG.backgroundColor}`,color: `${colorBG.color}`}}>
                <ul className="nav-menu-items" onClick={showSidebar}>
                    <li className="navbar-toggle" style ={{ backgroundColor: `${colorBG.backgroundColor}`, color: `${colorBG.color}`}}>
                        <Link to="#" className="menu-bars" style ={{ backgroundColor: `${colorBG.backgroundColor}`,color: `${colorBG.color}`}}>
                            <AiIcons.AiOutlineClose/>
                        </Link>
                    </li>

                    {SidebarData.map((item, index) => {
                        return (
                            <li key={index} className={item.cName} >
                                        <Link to={item.path} >
                                            <p style={{color:`${colorBG.color}`, backgroundColor: `${colorBG.backgroundColor}`}} >{t(item.title)} {t(item.title2)}</p>
                                        </Link>

                            </li>
                        );
                    })}
                </ul>
            </nav>
        </IconContext.Provider>
</div>
)

}

export default NavBarLaptop;
