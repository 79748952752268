import React, {useContext} from "react";
import {
    ButtonTable, ButtonTopFin, ButtonTopFinPopUp, ButtonTopFinPopUpDB, CardStyledS,
    CardTopSwitch, ContentPopUp, FullPage, GroupCardStyled,
    MarginLeftRight,
    MaxMinPairsCoinCSS,
    MGroupHeaderTableCSS,
    MsLinkFinCoCSS, MTtitleCSS,
} from "../../../AppStyledLaptop";
import {useTranslation} from "react-i18next";
import {v4 as uuid} from "uuid";
import Infinite from "react-infinite";
import {Link, useRouteMatch} from "react-router-dom";
import {CandleContext2} from "../../../../Provider/CandleProvider2";
import useWindowDimensions from "../../../../../service/useWindowDimensions";
import Popup from "reactjs-popup";
import '../../../AppLaptop.css'
import MaxMin24Column from "./Market24MaxMinColumn";
const MaxMin24 = () => {
    const {t} = useTranslation();
    let {url} = useRouteMatch();
    const {coin24, colorBG} = useContext(CandleContext2)
    const {  width } = useWindowDimensions();
    const MaxVolume = coin24.slice(0, 1961).sort(function (a, b) {
        return parseFloat(b.volume) - parseFloat(a.volume);
    });
    const MaxTrader = coin24.sort(function (a, b) {
        return parseFloat(b.count) - parseFloat(a.count);
    });

    return (
        <FullPage style ={{marginTop :'100px'}}>
            {width > 1000 ?(
                <MsLinkFinCoCSS>
                    <CardTopSwitch style={{background: `${colorBG.backgroundColor}`, color: `${colorBG.color}`}}>
                        <MarginLeftRight >
                            <MTtitleCSS><h3>{t("Top Active SpotMarket 24h")}</h3></MTtitleCSS>
                            <MGroupHeaderTableCSS>
                                <ButtonTable>
                                    <Popup trigger={() => (
                                        <ButtonTopFinPopUp style ={{ marginLeft: '0px',paddingLeft:'5px'}} className="button">
                                            <strong style={{}}>{t("Pair")} <em style={{fontSize: "11px"}}></em></strong>
                                        </ButtonTopFinPopUp>
                                    )}
                                           position="right center"
                                           closeOnDocumentClick

                                    >
                                        <ContentPopUp>
                                            <p style={{whiteSpace:'pre-wrap'}}>{t('base asset/quote asset')} </p>
                                            <Link to ='/share'>{t("and more")}</Link>
                                            </ContentPopUp>

                                    </Popup>

                                </ButtonTable>
                                <ButtonTable>
                                    <Popup trigger={() => (
                                        <ButtonTopFinPopUpDB  className="button">
                                                <strong style={{  padding:"10px"
                                                }}>{t("Volume")}<em
                                                    style={{fontSize: "11px"}}>($)</em></strong>
                                            <br/>
                                            <strong style={{  padding:"10px"
                                            }}>{t("PriceChange")}<em
                                                style={{fontSize: "11px"}}>(%)</em></strong>
                                        </ButtonTopFinPopUpDB >
                                    )}
                                           position="right center"
                                           closeOnDocumentClick

                                    >
                                        <ContentPopUp>
                                            <p style={{whiteSpace:'pre-wrap'}}>{t('Higher trading volumes are considered more positive than lower trading volumes because they mean more liquidity and better order execution')}</p>
                                            <Link to ='/share'>{t("and more")}</Link>
                                        </ContentPopUp>

                                    </Popup>
                                </ButtonTable>

                                <ButtonTable style ={{marginLeft:'50px'}}>
                                    <Popup trigger={() => (
                                        <ButtonTopFinPopUp  className="button">
                                                <strong style={{  padding:"5px"
                                                }}>{t("VWAP")} <em
                                                    style={{fontSize: "11px"}}>($)</em></strong>
                                        </ButtonTopFinPopUp>
                                    )}
                                           position="right center"
                                           closeOnDocumentClick

                                    >
                                        <ContentPopUp>
                                            <p>
                                                {t('Popup content')}
                                            </p>
                                            <Link to ='/share'>{t("and more")}</Link>
                                          </ContentPopUp>

                                    </Popup>
                                </ButtonTable>


                            </MGroupHeaderTableCSS>
                            <Infinite containerHeight={300}
                                      elementHeight={80}>
                                {MaxVolume.slice(0, 20).map((item) =>
                                    (
                                        <MaxMinPairsCoinCSS>
                                            <ButtonTable key={uuid()}>
                                                <Link style={{
                                                    textAlign: "center", color: `${colorBG.color}`,
                                                    background: `${colorBG.backgroundColor}`, textDecoration: "none"
                                                }}
                                                      to='/fin/crypto'>
                                                    {item.symbol}
                                                </Link>
                                            </ButtonTable>
                                            <ButtonTopFin>
                                                <ButtonTable key={uuid()}>
                                                    {new Intl.NumberFormat('en-US', {style: 'decimal'}).format(item.volume)}
                                                    <div>{item.priceChangePercent}</div>

                                                </ButtonTable>
                                            </ButtonTopFin>
                                            <ButtonTopFin>
                                                <ButtonTable key={uuid()}>
                                                    {parseFloat(item.weightedAvgPrice)}
                                                </ButtonTable>
                                            </ButtonTopFin>
                                        </MaxMinPairsCoinCSS>
                                    ))}
                            </Infinite>
                        </MarginLeftRight>
                    </CardTopSwitch>

                    <CardTopSwitch
                        style={{background: `${colorBG.backgroundColor}`, color: `${colorBG.color}`, marginLeft: "20px"}}>
                        <MarginLeftRight>
                            <MTtitleCSS><h3>{t("Top Trader SpotMarket 24h")}</h3></MTtitleCSS>
                            <MGroupHeaderTableCSS>
                                <ButtonTable>
                                    <strong>{t("Pair")} <em style={{fontSize: "11px"}}></em></strong>
                                </ButtonTable>


                                <ButtonTopFin>
                                    <ButtonTable>
                                        <Popup trigger={() => (
                                            <ButtonTopFinPopUpDB style={{marginLeft:'0px', width:"187px", paddingLeft:"10px"}} className="button">
                                                <strong>{t("Traders")} </strong>
                                                <em style={{fontSize: "11px"}}>(person)</em>
                                                <br/>
                                                <strong style={{
                                                }}>{t("PriceChange")}<em
                                                    style={{fontSize: "11px"}}>(%)</em></strong>
                                            </ButtonTopFinPopUpDB>
                                        )}
                                               position="bottom center"
                                               closeOnDocumentClick

                                        >
                                            <ContentPopUp>
                                                <p style={{whiteSpace:'pre-wrap'}}>{t('')}</p>
                                                <Link to ='/share'>{t("and more")}</Link>
                                            </ContentPopUp>

                                        </Popup>
                                    </ButtonTable>
                                </ButtonTopFin>
                                <ButtonTable>
                                    <ButtonTopFin>
                                        <strong style={{}}>{t("VWAP")} <em
                                            style={{fontSize: "11px"}}>($)</em></strong>
                                    </ButtonTopFin>
                                </ButtonTable>
                            </MGroupHeaderTableCSS>

                            <Infinite containerHeight={300}
                                      elementHeight={80}>
                                {MaxTrader.slice(0, 30).map((item) =>
                                    (
                                        <MaxMinPairsCoinCSS>
                                            <ButtonTable key={uuid()}>
                                                <Link style={{
                                                    textAlign: "center", color: `${colorBG.color}`,
                                                    background: `${colorBG.backgroundColor}`, textDecoration: "none"
                                                }}
                                                      to='/fin/crypto'>
                                                    {item.symbol}
                                                </Link>
                                            </ButtonTable>


                                            <ButtonTopFin>
                                                <ButtonTable key={uuid()}>
                                                    <div key={uuid()}>
                                                        {new Intl.NumberFormat('en-US', {style: 'decimal'}).format(item.count)}
                                                    </div>
                                                    <div>{item.priceChangePercent}</div>
                                                </ButtonTable>
                                            </ButtonTopFin>
                                            <ButtonTopFin>
                                                <ButtonTable key={uuid()}>
                                                    {parseFloat(item.weightedAvgPrice)}
                                                </ButtonTable>
                                            </ButtonTopFin>
                                        </MaxMinPairsCoinCSS>
                                    ))}
                            </Infinite>
                        </MarginLeftRight>

                    </CardTopSwitch>
                </MsLinkFinCoCSS>
            ):(<div><MaxMin24Column></MaxMin24Column></div>)
            }

            <GroupCardStyled style={{
                marginLeft:"200px"
            }}>
                <CardStyledS>
                    <Link to={`${url}/crypto`} style={{
                        color:"white"
                    }}>
                        <h3 style ={{color:`${colorBG.color}`,background:`${colorBG.backgroundColor}`}}>{t("Crypto Market 24h")}</h3>
                    </Link>
                </CardStyledS>

            </GroupCardStyled>
        </FullPage>

    );
};

export default MaxMin24;