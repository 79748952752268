import React, {useContext} from "react";
import {Area, Bar, CartesianGrid, Tooltip,ComposedChart, ResponsiveContainer, Line, XAxis, YAxis} from "recharts"
import {CovidWorldContext} from "../../../Provider/CovidProvider/CovidYesterday";
import {ButtonSEE, TitleCard} from "../../../../AppStyled";
import {useTranslation} from "react-i18next";
import {FullPage, LayoutRow} from "../../../AppLaptop/AppStyledLaptop";
import {CandleContext2} from "../../../Provider/CandleProvider2";


const VietnamCovid = () => {
    const {t} = useTranslation();
    const {colorBG} = useContext(CandleContext2)
    const {cardComponent1,cardComponent2,filterVNdtTopGreater1k,filterVNdtSmaller1k,vnHistoryCv, seeList, setVisibleList} = useContext(CovidWorldContext)


    return (
        <div >
            <FullPage style={{marginTop: "65px"}}>
                <em style={{fontSize: "10px",padding:'15px'}}>[ref:https://covid19.gov.vn]</em>
                <div>
                    <TitleCard>{t("Daily Deaths")} </TitleCard>
                    <ResponsiveContainer height={400}>
                        <ComposedChart height={250} data={vnHistoryCv}>
                            <XAxis dataKey="date" style={{fill: "white"}}/>
                            <YAxis style={{fill: "white"}}/>
                            <Tooltip/>
                            <CartesianGrid stroke="#f5f5f5"/>
                            <Bar dataKey="death" barSize={20} fill="#854442"/>
                        </ComposedChart>
                    </ResponsiveContainer>

                </div>

                <br/> <br/>

                <div>
                    <TitleCard>{t("Daily Cases")}</TitleCard>
                    <ResponsiveContainer height={400}>
                        <ComposedChart height={250} data={vnHistoryCv}>
                            <XAxis dataKey="date" style={{fill: "white"}}/>
                            <YAxis style={{fill: "white"}}/>
                            <Tooltip/>
                            <CartesianGrid stroke="#f5f5f5"/>
                            <Area type="monotone" dataKey="cases" fill="#ffdbac" stroke="#ff7300"/>
                            <Line type="monotone" dataKey="cases" stroke="#ff7300"/>
                        </ComposedChart>
                    </ResponsiveContainer>

                </div>
                <br/> <br/>
                <div>
                    <TitleCard>{t("Daily Hospitalized")}</TitleCard>
                    <ResponsiveContainer height={400}>
                        <ComposedChart width={320} height={250} data={vnHistoryCv}>
                            <XAxis dataKey="date" style={{fill: "white"}}/>
                            <YAxis style={{fill: "white"}}/>
                            <Tooltip/>
                            <CartesianGrid stroke="#f5f5f5"/>
                            <Bar dataKey="treating" barSize={20} fill="#7bc043"/>
                            <Line type="monotone" dataKey="treating" stroke="#7bc043"/>
                        </ComposedChart>
                    </ResponsiveContainer>
                </div>



            </FullPage>
            <br/>
            <FullPage style={{}} >


                    <LayoutRow  style={{border:"1px solid gray"}}>
                        <TitleCard style={{fontWeight: "bold", width: '150px'}}>{t("Province")}</TitleCard>
                        <TitleCard style={{fontWeight: "bold", width: '100px'}}>{t("Yesterday<1000")}</TitleCard>

                    </LayoutRow>
                    {cardComponent1}
                    <LayoutRow>
                        <ButtonSEE style={{
                            color: `${colorBG.color}`,
                            margin: "20px",
                            background: `${colorBG.backgroundColor}`
                        }}
                                   disabled={filterVNdtSmaller1k.length === cardComponent1.length}
                                   type="button"
                                   onClick={() => setVisibleList((prev) => prev + 100)}
                        >
                            {t("see more")}
                        </ButtonSEE>
                        {seeList && cardComponent1}
                        <ButtonSEE style={{
                            color: `${colorBG.color}`,
                            margin: "20px",
                            background: `${colorBG.backgroundColor}`
                        }}
                                   visable={cardComponent1.length === 5}
                                   type="button"
                                   onClick={() => setVisibleList(5)}
                        >
                            {t("see less")}
                        </ButtonSEE>
                        {seeList && cardComponent1}
                    </LayoutRow>
                </FullPage>
            <br/>



            <FullPage>
                    <LayoutRow  style={{border:"1px solid gray"}}>
                    <TitleCard style={{fontWeight: "bold", width: '150px',padding:'15px'}}>{t("Province")}</TitleCard>
                    <TitleCard style={{fontWeight: "bold", width: '100px',padding:'15px'}}>{t("Yesterday>=1000")}</TitleCard>
                </LayoutRow>
                {cardComponent2}
                <LayoutRow>
                    <ButtonSEE style={{
                        color: `${colorBG.color}`,
                        margin: "20px",
                        background: `${colorBG.backgroundColor}`
                    }}
                               disabled={filterVNdtTopGreater1k.length === cardComponent2.length}
                               type="button"
                               onClick={() => setVisibleList((prev) => prev + 100)}
                    >
                        {t("see more")}
                    </ButtonSEE>
                    {seeList && cardComponent2}
                </LayoutRow>
            </FullPage>


        </div>
    );
};

export default VietnamCovid;
