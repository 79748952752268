import { createSlice,createAsyncThunk } from '@reduxjs/toolkit'
import axios from "axios";
import {Africa, Asia, AustraliaOceania, Europe, NorthAmerica, SouthAmerica} from "../contants/CONTINENT";



export const fetchVaccineCovid= createAsyncThunk(
    "vaccineCovid/fetchVaccineCovid",
    async () => {
        const promises = [];
        const urls = ['https://disease.sh/v3/covid-19/vaccine/coverage/countries?lastdays=1&fullData=false',
            'https://disease.sh/v3/covid-19/countries?yesterday=yesterday&sort=deathsPerOneMillion']

        urls.forEach(url=>{
            promises.push(axios.get(url))
        })

        const results = await Promise.all(promises);

        const resVaccine = results[0].data.map(chil => {
            return {
                country: chil.country,
                vaccine: Object.values(chil.timeline)[0]
            }
        })

        const CountryCovid = results[1].data.map(chil => {
            return {
                country: chil.country,
                population:chil.population,
                casesPerOneMillion: chil.casesPerOneMillion,
                deathsPerOneMillion: chil.deathsPerOneMillion,
                recoveredPerOneMillion: chil.recoveredPerOneMillion,
                continent:chil.continent
            }
        })

        let merged = [];

        for (let i = 0; i < resVaccine.length; i++) {
            merged.push({
                    ...resVaccine[i],
                    ...(CountryCovid.find((itmInner) => itmInner.country === resVaccine[i].country))
                }
            );
        }

        const Combine = merged.map(chil=>{return{
            country: chil.country,
            vaccinePerOneMillion:((chil.vaccine * 1000000)/chil.population),
            casesPerOneMillion: chil.casesPerOneMillion,
            deathsPerOneMillion: chil.deathsPerOneMillion,
            recoveredPerOneMillion: chil.recoveredPerOneMillion,
            continent:chil.continent
        }})


        const neededData = [
            {Continent: "Asia", Stat: Combine.filter(itemX => Asia.includes(itemX.country))},
            {Continent: "Africa", Stat:  Combine.filter(itemX => Africa.includes(itemX.country))},
            {Continent: "Europe", Stat:  Combine.filter(itemX => Europe.includes(itemX.country))},
            {Continent: "AustraliaOceania", Stat:  Combine.filter(itemX => AustraliaOceania.includes(itemX.country))},
            {Continent: "NorthAmerica", Stat:  Combine.filter(itemX => NorthAmerica.includes(itemX.country))},
            {Continent: "SouthAmerica", Stat:  Combine.filter(itemX => SouthAmerica.includes(itemX.country))}]
        return neededData;

    }
);

export const initialState = {
    vaccineCovid:[],
}


const VaccineCovidSlice = createSlice({
    name: 'vaccineCovid',
    initialState,
        reducers: {

        },
        extraReducers: {
            [fetchVaccineCovid.pending]: () => {
                console.log("Pending");
            },
            [fetchVaccineCovid.fulfilled]: (state, {payload}) => {
                console.log("Fetched Successfully!");
                return {...state, vaccineCovid: payload}
            },
            [fetchVaccineCovid.rejected]: () => {
                console.log("Rejected!");
            },

        }
    }

)

// export const {
// } = VaccineCovidSlice.actions

export const VaccineCovidSelector = state => state.vaccineCovid.vaccineCovid
export default VaccineCovidSlice.reducer
