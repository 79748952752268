import firebase2 from "./firebase02";

const db = firebase2.database().ref("/mainData");

export const getAll = () => {
  return db;
};

export const create = (data) => {
  return db.push(data);
};

export const update = (key, data) => {
  return db.child(key).update(data);
};

export const remove = (key) => {
  return db.child(key).remove();
};

export const removeAll = () => {
  return db.remove();
};

