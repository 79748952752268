import { configureStore } from '@reduxjs/toolkit'
import covid90Reducer from "./Covid90d"
import logger from 'redux-logger';
import vaccineCovidReducer from "./VaccineCovidSlice"
import coins24Reducer from "./coinsSlice"



export const store = configureStore(
    { reducer: {
            coins24s: coins24Reducer,
            covid90: covid90Reducer,
            vaccineCovid:vaccineCovidReducer,
        },middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
    })