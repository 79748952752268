import React, {useState} from "react";
import {useSelector} from 'react-redux'
import {Covid90Selector} from "../../../../redux-tooklit-app/Covid90d";
import {v4 as uuidv4} from 'uuid';
import {
    LayoutRow,
    MobileFl, Padding4small, ViewButton
} from "../../../../AppLaptop/AppStyledLaptop";
import SearchCountry from "../../../Component/Health/Covid/CovidComponent/SearchCountry/SearchCountry";
import TopComMB from "./RankCom";
import {Link, useRouteMatch} from "react-router-dom";
import {useTranslation} from "react-i18next";

const Fluctuation90d = () => {
    const {t} = useTranslation()
    const {url} = useRouteMatch()
    const covid90d = useSelector(Covid90Selector)
    const [selectCon, setSelectCon] = React.useState("");
    const [search, setSearch] = useState('')
    const handleChange = (e) => {
        e.preventDefault()
        setSearch(e.target.value);
    };

    return (
        <MobileFl style ={{}}>
                    <div>
                            <div style={{paddingTop:'20px'}}>
                                <strong>COVID19-FLUCTUATION </strong>
                                <Link style ={{color:'white', fontSize:"18px",marginLeft:"20px"}} to ={`${url}/top`}> TOP10</Link>

                            </div>

                        <LayoutRow style={{paddingTop:'20px',paddingLeft:'20px'}}>
                                <select style={{height: '35px'}} value={selectCon}
                                        onChange={(e) => setSelectCon(e.currentTarget.value)}>
                                    <option value=''>
                                        All
                                    </option>
                                    {covid90d.map(item => {

                                        return (
                                            <option value={item.Continent}>
                                                {item.Continent}
                                            </option>
                                        )
                                    })}
                                </select>
                                <SearchCountry handleChangeCountry ={handleChange}/>
                            </LayoutRow>


                    </div>


            <div>
                {covid90d.slice(0, 1).filter(({Continent}) => selectCon ? Continent === selectCon : true).map(item => {
                    return (
                        <div>
                            <h2 style={{marginLeft:"25px"}}>{item.Continent}</h2>
                            <div key={uuidv4()}>
                                            {item.Stat[0].data.slice(0, 2).filter((subItem) => {
                                                return subItem.country.toLowerCase().includes(search.toLowerCase());
                                            }).map(subItem => (
                                                <div>
                                                    <TopComMB GraphicData={subItem}></TopComMB>
                                                </div>
                                            ))}
                            </div>

                        </div>


                    )
                })}
                <br/>
                <Link to={`${url}/fluctuation`} style={{textDecoration: "none"}}>
                    <ViewButton>{t("ViewFull")}</ViewButton>
                </Link>
                <br/>
            </div>
        </MobileFl>

    );
}
export default Fluctuation90d;
