import React, {useContext} from "react";
import {
    Route,
    Link, useRouteMatch, Switch,
} from "react-router-dom";
import {
    CardStyledS, GroupCardStyled
} from "../../AppStyledLaptop";

import FinanceCRT from "./FinanceCRT";
import MaxMin24 from "../../Component/Finance/TabCoin/Market24MaxMin";
import {ButtonSEE} from "../../../../AppStyled";
import {useTranslation} from "react-i18next";
import {CandleContext2} from "../../../Provider/CandleProvider2";

export const LinksFinCo = () => {
    const {colorBG} = useContext(CandleContext2)
    let {path, url} = useRouteMatch();
    const {t} = useTranslation();
    return (
        <div>

            <Switch>
                <Route exact path={path} component={MaxMin24}/>
                <Route path={`${path}/crypto`} component={FinanceCRT}/>
            </Switch>


        </div>

    )
}