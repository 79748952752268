import React from "react";
import {
    Route,
    useRouteMatch, Switch,
} from "react-router-dom";
import '../../AppMobile.css'
import SpotMarketCoin from "../../Component/Finance/SpotMarketCoin";

export const Fin = () => {
    let {path} = useRouteMatch();
    return (
        <div>

            <Switch>
                <Route exact path={path} component={SpotMarketCoin}/>
            </Switch>

        </div>

    )
}