import React from 'react';
import {SearchCSS} from "../../../../../AppStyledMobile";
import {useTranslation} from "react-i18next";

const SearchCountry = (props) => {
    let handleChange = props.handleChangeCountry
    const {t} = useTranslation();

    return (
            <SearchCSS>
                <input
                    style={{ height:'30px',marginLeft :"20px",color:"black",border: "1px solid gray" }}
                    type='text'
                    onChange={handleChange}
                    placeholder={t('🔍Search country')}
                    id='searchALTS'
                />
            </SearchCSS>
    )

}
export default SearchCountry;