import React  from "react";
import {TinyLineChart} from "../../../Component/Health/Covid/CovidComponent/ChartCom/TinyLineChart";



const TopComMB = React.memo((props) => {
    let Item = props.GraphicData;
    return (
                    <div>
                        <div style={{}}>
                            <TinyLineChart data={Item.Covid30days}/>
                            <div style={{border:"0.1px solid gray" , display: "flex", width:'350px',justifyContent: "center"}}>
                                <h4 style={{paddingLeft:'10px'}}> {Item.country}</h4>
                                <div style={{width:'320px',padding:'5px',textAlign:'center'}}>
                                    <div>{Item.balance > 0 ? (<div>
                                        {Item.balance > 50?(
                                            <p style={{color:'red'}}>
                                                {'+' + `${Number.parseFloat(Item.balance).toFixed(2)}` +"% "+ 'High Risk'}
                                            </p>

                                        ):(
                                            <p style={{color:''}}>
                                                {'+' + `${Number.parseFloat(Item.balance).toFixed(2)}` +"% "+ 'Low Risk'}
                                            </p>
                                        ) } </div> )  : ('-' + `${Number.parseFloat(Item.balance).toFixed(2)}`+"%")}</div>
                                </div>
                            </div>
                        </div>

                    </div>


    );
});

export default TopComMB;