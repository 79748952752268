import React from "react";
import { LineChart, Line, XAxis,Tooltip,CartesianGrid} from "recharts";

export const TinyLineChart = ({data}) =>
    {

    return (
        <div style={{border: "0.1px solid gray"}}>
            <LineChart  width={400} height={200} data={data} padding={10} >
                <XAxis
                    dataKey="date"
                    stroke="inherit"
                    tick={true}
                    hide={false}
                    style={{fill:'white'}}
                />
                <CartesianGrid stroke="#eee" strokeDasharray="5 5"/>
                <Line
                    yAxisId="left"
                    activeDot={{ r: 4 }}
                    dot={false}
                    type="monotone"
                    dataKey="case"
                    stroke="#f37736"
                    strokeWidth={3}
                />
                <Tooltip cursor={true} formatter={(value) => new Intl.NumberFormat('en').format(value)} />
            </LineChart>
        </div>
    );
}



