
const useTransferData = (fetchData)=>{
    const forCovid30days = fetchData.filter(item=>!!item).map(child => {
        const valDt = Object.values(child.timeline.cases);
        const keyDt = Object.keys(child.timeline.cases)
        const Arr01 = valDt.map(chil => {
            return {
                case: (chil)
            }
        })

        const Arr02 = keyDt.map(chil => {
            return {
                date: chil
            }
        })
        const Arr03 = (valDt[29]-valDt[0])*100/valDt[0]
        const Covid30days = Arr02.map((item, i) => Object.assign({}, item, Arr01[i]))
        child.Covid30days = Covid30days;
        child.balance =Arr03
        return {
            Covid30days:child.Covid30days,
            country: child.country,
            balance:child.balance
        }
    })

    return {forCovid30days}


}
export default useTransferData