import React from "react";
import {useTranslation} from "react-i18next";

const InvestCRT =  () => {
    const {t} = useTranslation();

    return (
        <div>
            <em style={{color:"gray", fontSize:"14px", margin:"60px"}}>{t("Coming soon...!")}</em>
        </div>

    );
};
export default InvestCRT
