import React, {useContext, useEffect, useRef} from "react";
import {createChart, CrosshairMode} from 'lightweight-charts';
import '../../../AppLaptop.css'
import {sma} from "moving-averages";
import {CandleContext2} from "../../../../Provider/CandleProvider2";
import {RSI} from "technicalindicators";

const FinChart = ({data}) => {
    const chartContainerRef = useRef();
    const chart = useRef();
    const {colorBG} = useContext(CandleContext2)
    const resizeObserver = useRef();


    useEffect(() => {
        chart.current = createChart(chartContainerRef.current, {
            width: chartContainerRef.current.clientWidth,
            height: chartContainerRef.current.clientHeight,
            layout: {
                backgroundColor:`${colorBG.backgroundColor}`,
                textColor: 'rgba(255, 255, 255, 0.9)',
            },
            grid: {
                vertLines: {
                    color: '#334158',
                },
                horzLines: {
                    color: '#334158',
                },
            },
            crosshair: {
                mode: CrosshairMode.Normal,
            },
            priceScale: {
                borderColor: '#485c7b',
            },
            timeScale: {
                borderColor: '#485c7b',
            },
        });
        const candleSeries = chart.current.addCandlestickSeries({
            upColor: '#4bffb5',
            downColor: '#ff4976',
            borderDownColor: '#ff4976',
            borderUpColor: '#4bffb5',
            wickDownColor: '#838ca1',
            wickUpColor: '#838ca1',
        });
        candleSeries.applyOptions({
            priceFormat: {
                type: 'custom',
                minMove: 0.00000001,
                formatter: price => price.toFixed(8),
            },
        });
        candleSeries.setData(data);

        const volumeSeries = chart.current.addHistogramSeries({
            color: 'gray',
            lineWidth: 2,
            priceFormat: {
                type: 'volume',
            },
            overlay: true,
            scaleMargins: {
                top: 0.8,
                bottom: 0,
            },
        });
        volumeSeries.applyOptions({
            priceFormat: {
                type: 'custom',
                minMove: 0.01,
                formatter: value => new Intl.NumberFormat().format(value)
            },
        });

        volumeSeries.setData(data);


        const movingAverage100 = sma(
            data.map((x) => x.open),
            10
        ).map((value, index) => ({ value, time: data[index].time }));
        const MALine = chart.current.addLineSeries({
            color: "Yellow",
            title: "SMA100"
        })
        if (data.length >50||data.length ===50) {
            MALine.setData(movingAverage100);
        }

        const movingAverage50 = sma(
            data.map((x) => x.open),
            5
        ).map((value, index) => ({ value, time: data[index].time }));
        const MALine50 = chart.current.addLineSeries({
            color: "blue",
            title: "SMA50"
        })
        if (data.length >50||data.length ===50) {
            MALine50.setData(movingAverage50);
        }


            const inputRSI = {
                values : data.map(item=>item.close).slice(15,-1),
                period : 14
            };
            console.log('rsi',RSI.calculate(inputRSI).slice(-14))



    }, []);

    // Resize chart on container resizes.
    useEffect(() => {
        resizeObserver.current = new ResizeObserver(entries => {
            const {width, height} = entries[0].contentRect;
            chart.current.applyOptions({width, height});
            setTimeout(() => {
                chart.current.timeScale().fitContent();
            }, 0);
        });
        resizeObserver.current.observe(chartContainerRef.current);

        return () => resizeObserver.current.disconnect();
    }, []);
    return (
        <div className="FinLaptop">
            <div ref={chartContainerRef} className="chart-containerLaptop"/>
        </div>
    );
};

export default FinChart;