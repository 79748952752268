import React, {useState} from "react";
import DisplayBMI from "./DisplayBMI";
import Form from "./Form";

function BMICheck() {
    const [height, setHeight] = useState(0);
    const [weight, setWeight] = useState(0);
    const [bmi, setBMI] = useState(0);

    const convertHeight = (e) => setHeight(e.target.value);

    const convertWeight = (e) => setWeight(e.target.value);

    const calculateBMI = (e) => {
        e.preventDefault();
        const calculate = Number(weight) / Math.pow(Number(height), 2);
        setBMI(Number(calculate).toFixed(2));
    };


    return (
        <div style={{color: "inherit"}}>

            <Form
                weightValue={weight}
                updateWeight={convertWeight}
                heightValue={height}
                updateHeight={convertHeight}
                submitValues={calculateBMI}/>
            <DisplayBMI bmiValue={bmi}/>

        </div>
    );
}

export default BMICheck;
