import React,{useState} from 'react'
export const useFilterData = (Dt,list)=>
{
    const top = Dt.filter(itemX => list.includes(itemX.country));
    const low = Dt.filter(itemX => list.includes(itemX.country))
    return {top,low}
}
//
// export const useSortData = (Dt,key1,key2)=>
// {
//     const sortContinent = Dt.filter(item=>item.Continent.includes(key1));
//     const sortBoth = Dt.filter(item=>item.rank.includes(key2)&& item.Continent.includes(key1))
//     const sortRank = Dt.filter(item=>item.rank.includes(key2))
//     return {sortContinent,sortBoth,sortRank}
// }

export const useFilter =  ((CoinsData) => {
    const [search, setSearch] = useState('')
    const handleChange = (e) => {
        setSearch(e.target.value)
    }

    const filteredCoin = React.useMemo(
        () =>
            CoinsData.filter((dt) => {
                return dt.label.toLowerCase().includes(search.toLowerCase());
            }),
        [search,CoinsData]
    );


    return {handleChange,filteredCoin}
} )