import TabsPanel from "../../AppComponent/TabComponent/TabsPanel";
import Tab from "../../AppComponent/TabComponent/Tab";
import React from "react";

import '../AppLaptop.css'
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {CoinsBinSelector} from "../../redux-tooklit-app/coinsSlice";
import {Graphic} from "../../GraphicCom/Graphic";
import {Graphic300} from "../../GraphicCom/Graphic300";
import {Graphic200} from "../../GraphicCom/Graphic200";
import {Graphic100} from "../../GraphicCom/Graphic100";
import {Graphic14} from "../../GraphicCom/Graphic14";
import {Graphic50} from "../../GraphicCom/Graphic50";



const ChartPeriods = () => {
    const {t} = useTranslation();
    const coinBin = useSelector(CoinsBinSelector)
    const CandleChart = Object.values(coinBin).flat()
    return (
        <div >
            <div >
                <TabsPanel>
                    <Tab
                        title={t("All")}
                        subtitle={t("All")}
                        icon={t("All")}>
                        <Graphic CandleChart ={CandleChart}/>
                    </Tab>
                    <Tab
                        title={t("300days")}
                        subtitle={t("300days")}
                        icon={t("300days")}>
                        <Graphic300 CandleChart ={CandleChart}/>
                    </Tab>
                    <Tab
                        title={t("200days")}
                        subtitle={t("200days")}
                        icon={t("200days")}>
                        <Graphic200 CandleChart ={CandleChart}/>
                    </Tab>


                    <Tab
                        title={t("100days")}
                        subtitle={t("100days")}
                        icon={t("100days")}>

                        <Graphic100 CandleChart ={CandleChart}/>

                    </Tab>
                    <Tab
                        title={t("50days")}
                        subtitle={t("50days")}
                        icon={t("50days")}>
                        <Graphic50 CandleChart ={CandleChart} />
                    </Tab>

                    <Tab
                        title={t("14days")}
                        subtitle={t("14days")}
                        icon={t("14days")}>
                        <Graphic14 CandleChart ={CandleChart}/>
                    </Tab>
                </TabsPanel>
            </div>
        </div>
    )
}
export default ChartPeriods;