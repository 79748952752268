import React, {useContext} from 'react';
import {CovidWorldContext} from "../../../../../Provider/CovidProvider/CovidYesterday";
import {v4 as uuidv4} from "uuid";
import SearchCVTODAY from "./SearchCVTODAY";
import {
    ButtonTop,
    CardTitle, CCSBorder,
    CovidWorldTodayLAP, FullPage, GroupCardStyled,
    Padding,
    TitleLeft, TitleMain, TopCss, ViewButton,
} from "../../../../AppStyledLaptop";
import {Link, useRouteMatch} from "react-router-dom";
import {useTranslation} from "react-i18next";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {responsive} from "../../../../../../service/constantsVariable";
import Fluctuation90dLaptop from "./30d/FluctuationCovid90d";
import {useSelector} from "react-redux";
import {VaccineCovidSelector} from "../../../../../redux-tooklit-app/VaccineCovidSlice";
import BMICheck from "../../BMI/BMICheck";
import './../../../../../../App.css'

const EntireWorld = () => {
    const {filteredCountry, handleChange} = useContext(CovidWorldContext)
    const vaccineCovid = useSelector(VaccineCovidSelector)
    const {t} = useTranslation();
    const {url} = useRouteMatch()


    return (
        <div>
            <FullPage style ={{marginTop:"100px"}}>
                <TitleMain style={{}}>
                    {t("COVID19-TODAY")}<span><em
                    style={{fontSize: "10px"}}>[ref:https://www.worldometers.info]</em></span></TitleMain>
                <SearchCVTODAY handleChange={handleChange}/>
                <div style={{position: 'relative'}}>
                    <Carousel responsive={responsive}>
                        {filteredCountry.length > 0 ? filteredCountry.map((item) => (
                            <CovidWorldTodayLAP key={uuidv4()}>
                                <TitleLeft style={{}} key={item.country}>
                                    <strong>{item.country}</strong>
                                </TitleLeft>
                                <TitleLeft>
                                    {new Intl.NumberFormat().format(item.todayCases)}
                                </TitleLeft>
                            </CovidWorldTodayLAP>
                        )) : (<div></div>)}
                    </Carousel>
                </div>
            </FullPage>

            <br/> <br/>

            <FullPage>
                <GroupCardStyled>
                    <CCSBorder>
                        <Padding style={{marginLeft:'35px'}}>
                            <label htmlFor='continent'
                                   style={{color: "inherit", fontWeight: "bolder"}}> COVID19-TOP10 </label>

                        </Padding>
                        <Padding>
                            {vaccineCovid.slice(0,1).map(item => (
                                <div>
                                    <div>
                                        <CardTitle style={{color: "inherit"}}>{t("Cases")}<span
                                            style={{color: "gray", fontSize: "11px"}}>/{t("1M.per")}
                                                                   </span>
                                        </CardTitle>
                                        {item.Stat.flat().slice(0, 5).sort((a, b) => b.casesPerOneMillion - a.casesPerOneMillion).slice(0, 10).map(subItem =>
                                            (
                                                <div>
                                                    <TopCss key={uuidv4()}>
                                                        <TitleLeft>{subItem.country}</TitleLeft>
                                                        <ButtonTop>{new Intl.NumberFormat('en').format(subItem.casesPerOneMillion)}</ButtonTop>
                                                    </TopCss>
                                                </div>

                                            )
                                        )}

                                        <br/>
                                        <div>
                                            <CardTitle style={{color: "inherit"}}>{t("Deaths")}
                                                <span
                                                    style={{
                                                        color: "gray",
                                                        fontSize: "11px"
                                                    }}>/{t("1M.per")}
                                                                </span>
                                            </CardTitle>
                                            {item.Stat.flat().slice(0, 5).sort((a, b) => b.deathsPerOneMillion - a.deathsPerOneMillion).slice(0, 10).map(subItem =>
                                                (
                                                    <TopCss key={uuidv4()}>
                                                        <TitleLeft>{subItem.country}</TitleLeft>
                                                        <ButtonTop>{new Intl.NumberFormat('en').format(subItem.deathsPerOneMillion)}</ButtonTop>
                                                    </TopCss>
                                                )
                                            )}
                                        </div>
                                        <br/>
                                        <div>
                                            <CardTitle style={{color: "inherit"}}>{t("Vaccine_Doses")}
                                                <span
                                                    style={{color: "gray", fontSize: "11px"}}>/{t("1M.per")}
                                                                </span>
                                            </CardTitle>
                                            {item.Stat.flat().slice(0, 5).sort((a, b) => b.vaccinePerOneMillion - a.vaccinePerOneMillion).slice(0, 10).map(subItem =>
                                                (<TopCss key={uuidv4()}>
                                                        <TitleLeft>{subItem.country}</TitleLeft>
                                                        <ButtonTop>{new Intl.NumberFormat('en').format(subItem.vaccinePerOneMillion)}</ButtonTop>
                                                    </TopCss>
                                                )
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Padding>
                        <br/>
                        <Link to={`${url}/top`} style ={{textDecoration: "none"}}>
                            <ViewButton>{t("ViewFull")}</ViewButton>
                        </Link>
                        <br/>
                    </CCSBorder>

                    <CCSBorder>
                        <Fluctuation90dLaptop />
                    </CCSBorder>

                    <CCSBorder>
                        <Padding>
                            <Link to="/CovidVN" style={{
                                color: "white", textDecoration: "none"
                            }}>➡ {t("Vietnam Covid 19 more detail")}</Link>
                            <BMICheck/>

                        </Padding>

                    </CCSBorder>
                </GroupCardStyled>
            </FullPage>

        </div>
    )
}
export default EntireWorld;