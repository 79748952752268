import React, {useContext, useEffect} from "react";
import { useDispatch, useSelector } from 'react-redux'
import {Link, useParams} from "react-router-dom";
import { ExInfoSelector, fetchCoin, fetchExchangeInfo} from "../redux-tooklit-app/coinsSlice";
import ChartPeriods from "./Component/ChartPeriods";
import {FullPage, LayoutRow, LayoutRow2, SymCSS} from "./AppStyledLaptop";
import {CandleContext2} from "../Provider/CandleProvider2";
import {useTranslation} from "react-i18next";


const DetailHistoryCoin = () => {
    const { symbol } = useParams();
    const dispatch = useDispatch()
    const {t} = useTranslation();
    const {colorBG} = useContext(CandleContext2)

    useEffect(() => {
        dispatch(fetchExchangeInfo(symbol))
        dispatch(fetchCoin(symbol));
    }, [dispatch])

    // const coinBin = useSelector(CoinsBinSelector)
    const exInfo = useSelector(ExInfoSelector)




    return (
        <FullPage style ={{marginTop:"100px"}}>
            <SymCSS>
                {exInfo.length > 0 && exInfo != null ? exInfo.map(item=>
                    (<div>
                        <h1>{item.baseAsset}-{item.quoteAsset}</h1>
                    </div>)):('') }
            </SymCSS>
            <LayoutRow2>
                <ChartPeriods/>
                <Link to ='/fin' style ={{color:`${colorBG.color}`, fontStyle:"italic"}}>{t("Back")}</Link>

            </LayoutRow2>
        </FullPage>



    );
}
export default DetailHistoryCoin
