import React from "react";
import {useTranslation} from "react-i18next";


function DisplayBMI({ bmiValue }) {
    const {t} = useTranslation()
    const status = (value) => {
        if (value < 18.5) return t("Underweight") ;
        if (value >18.5 && value <=23) return t("Healthy");
        if (value > 23 && value < 27.5) return " risk , need to lose 5% to 10% of weight ";
        else return "Obesity, need to lose 5%-10% of weight";
    };

    return bmiValue ? (
        <section>
            <h1>{t("Your BMI")} : {bmiValue}</h1>
            {(bmiValue > 23) && (bmiValue <27.5) || (bmiValue >27.5) || (bmiValue<18.5)?
                    (<p style={{color:"yellow",background:"black", textAlign:"center"}}>{status(bmiValue)}</p>):
                    (<p style={{color:"green"}}> {status(bmiValue)}</p>) }
        </section>
    ) : (
        ""
    );
}

export default DisplayBMI;
