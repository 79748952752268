import React, {useContext, useEffect} from "react";
import { useDispatch, useSelector } from 'react-redux'
import {Link, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import ChartPeriodsMobile from "./Finance/ChartPeriodsMobile";
import {CandleContext2} from "../../Provider/CandleProvider2";
import {ExInfoSelector, fetchCoin, fetchExchangeInfo} from "../../redux-tooklit-app/coinsSlice";
import {FullPage} from "../../AppLaptop/AppStyledLaptop";


const DetailHistoryCoinMobile = () => {
    const { symbol } = useParams();
    const dispatch = useDispatch()
    const {t} = useTranslation();
    const {colorBG} = useContext(CandleContext2)

    useEffect(() => {
        dispatch(fetchExchangeInfo(symbol))
        dispatch(fetchCoin(symbol));
    }, [dispatch])

    const exInfo = useSelector(ExInfoSelector)




    return (
        <div>
            <FullPage style ={{marginTop:"50px"}}>
                <div>
                    {exInfo.length > 0 && exInfo != null ? exInfo.map(item=>
                        (<div style ={{ padding:"20px"}}>
                            <h1>{item.baseAsset}-{item.quoteAsset}</h1>
                        </div>)):('') }
                </div>
                <ChartPeriodsMobile/>
                <Link to ='/fin/' style ={{color:`${colorBG.color}`, fontStyle:"italic", marginLeft:"20px"}}>{t("Back")}</Link>
                <br/>  <br/>
            </FullPage>
        </div>



    );
}
export default DetailHistoryCoinMobile
