import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from "i18next-browser-languagedetector";
import en from './locales/en.json'
import vn from './locales/vn.json'

const fallbackLng = 'en';
const defaultNS = 'translation';
const lng = localStorage.getItem('i18next') || fallbackLng;


const resources = {
    en: {
        common: {
            ...en
        }
    },
    vn: {
        common: {
            ...vn
        }
    },
};

i18n
    .use(LanguageDetector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng,
        ns: [defaultNS, 'local'],
        defaultNS,
        fallbackNS: ['local', 'common'],

        keySeparator: false, // we do not use keys in form messages.welcome
        interpolation: {
            escapeValue: false, // react already safes from xss
            formatSeparator: ","
        },
        react: {
            wait: true
        }
    });

console.log(i18n)
export default i18n;