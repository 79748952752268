import React from "react";
import {
    Switch,
    Route,
    useRouteMatch
} from "react-router-dom";

import EntireWorld from "../../Component/Health/Covid/CovidComponent/CovidWorld";
import {MarginMain} from "../../../../AppStyled";
import FullTop from "../../Component/Health/Covid/CovidComponent/FullTopGlobal";
import Fluctuation90FULL from "../../Component/Health/Covid/CovidComponent/FullFluatuationGlobal";

const BigHealthy = () => {
    let {path} = useRouteMatch();
    return (
        <MarginMain>
            <div>
                <div>
                    <Switch>
                        <Route exact path={path}>
                            <EntireWorld/>
                        </Route>
                        <Route exact path={`${path}/top`}>
                            <FullTop/>
                        </Route>
                        <Route exact path={`${path}/fluctuation`}>
                            <Fluctuation90FULL/>
                        </Route>
                    </Switch>
                </div>

            </div>
        </MarginMain>

    )
}
export default BigHealthy;