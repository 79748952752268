import React from "react";
import {
    ContainerFormReset, ContainerFormReset2,
    FullPage,
    MarginButtonTable,
    MarginButtonTable2
} from "../../../../AppLaptop/AppStyledLaptop";
import {useTranslation} from "react-i18next";


function Form({
                  weightValue,
                  updateWeight,
                  heightValue,
                  updateHeight,
                  reCal,
                  submitValues
              }) {
    const {t} = useTranslation();

    return (
        <FullPage style ={{marginTop:"60px"}}>
            <MarginButtonTable2>
                <h3> {t("Check your IBM")} ? </h3>
                <MarginButtonTable2>
                    <div>
                        <div>
                            <label  htmlFor="weight">{t("Weight")} (kg)</label>
                            <input id="weight" value={weightValue} onChange={updateWeight}/>
                        </div>
                        <div>
                            <label  htmlFor="height">{t("Height")} (m)</label>
                            <input  id="height" value={heightValue} onChange={updateHeight}/>
                        </div>

                    </div>
                </MarginButtonTable2>

                <MarginButtonTable2>
                    <ContainerFormReset2>

                        <button disabled={!weightValue && !heightValue} onClick={submitValues}>
                            {t("Calculate")}
                        </button>
                        <button  onClick={reCal}>{t("Reset")}</button>
                    </ContainerFormReset2>
                </MarginButtonTable2>
            </MarginButtonTable2>

        </FullPage>
    );
}

export default Form;
