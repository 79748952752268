import React from "react";
import {useSelector} from 'react-redux'
import {Covid90Selector} from "../../../../../../redux-tooklit-app/Covid90d";
import {v4 as uuidv4} from 'uuid';
import {

    LayoutRow,
    Padding, ViewButton,

} from "../../../../../AppStyledLaptop";
import TopCom from "./RankCom";
import {useTranslation} from "react-i18next";
import {Link, useRouteMatch} from "react-router-dom";

const Fluctuation90dLaptop = () => {
    const covid90d = useSelector(Covid90Selector)
    const {t} = useTranslation()
    const {url} = useRouteMatch()

    return (
        <div style={{marginLeft: '35px'}}>
            <LayoutRow>
                <Padding>
                    <label htmlFor="select">
                        <strong>{t("COVID19-FLUCTUATION")} </strong>
                    </label>
                </Padding>
            </LayoutRow>
            <Padding>
                {covid90d.slice(0, 1).map(item => {
                    return (
                        <div>

                            <div key={uuidv4()} style={{width: "570px"}}>
                                {item.Stat[0].data.slice(0, 3).map(filteredCountry90 => (
                                    <div>
                                        <TopCom GraphicData={filteredCountry90}></TopCom>
                                        <br/>
                                    </div>
                                ))}
                            </div>

                        </div>
                    )
                })}
            </Padding>
            <Link to={`${url}/fluctuation`} style={{textDecoration: "none"}}>
                <ViewButton>{t("ViewFull")}</ViewButton>
            </Link>
            <br/>
        </div>

    );
}
export default Fluctuation90dLaptop;
