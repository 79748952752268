import React, {useContext} from "react";
import {
    Switch,
    Route,Link,
    useRouteMatch } from "react-router-dom";
import {PageMobile} from "../../AppStyledMobile";
import EntireWorld from "../../Component/Health/Covid/CovidComponent/CovidWorld";
import '../../AppMobile.css'
import {CandleContext2} from "../../../Provider/CandleProvider2";
import BMICheck from "../../Component/Health/BMI/BMICheck";
import Fluctuation90FULL from "../../../AppLaptop/Component/Health/Covid/CovidComponent/FullFluatuationGlobal";
import FullTop from "../../../AppLaptop/Component/Health/Covid/CovidComponent/FullTopGlobal";
import {FullPage} from "../../../AppLaptop/AppStyledLaptop";
import {useTranslation} from "react-i18next";
import Fluctuation90FULLMB from "../../Component/Health/Covid/CovidComponent/FullFluatuationGlobalMB";


const  BeHealth=()=>{
    let { path ,url} = useRouteMatch();
    const {colorBG} = useContext(CandleContext2)
    const {t} = useTranslation();

    return (
        <PageMobile>
            <Switch>
                <Route exact path={path}>
                    <EntireWorld/>
                   <FullPage>
                       <Link to="/CovidVN" style={{
                           color: "white", fontSize:"18px", padding: "10px"
                       }}>{t("Vietnam Covid 19 more detail")}</Link>
                       <Link style ={{color:'white', fontSize:"18px",marginLeft:"20px"}} to ={`${url}/healthy-index`}> {t("Healthy Index")}</Link>
                   </FullPage>

                </Route>

                <Route exact path={`${path}/top`}>
                    <FullTop/>
                </Route>

                <Route exact path={`${path}/fluctuation`}>
                    <Fluctuation90FULLMB/>
                </Route>
                <Route exact path={`${path}/healthy-index`}>
                    <BMICheck/>
                </Route>
            </Switch>
        </PageMobile>
    )
}
export default BeHealth;