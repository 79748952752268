import './Components/AppLaptop/AppLaptop.css';
import React, { useEffect} from "react";
import { useMediaQuery } from "react-responsive";
import AppLaptop from "./Components/AppLaptop/AppLaptop";
import AppMobile from "./Components/AppMobile/AppMobile";
import {CandleProvider2} from "./Components/Provider/CandleProvider2";
import {CovidWorldProvider} from "./Components/Provider/CovidProvider/CovidYesterday";
import {HeaderCSS} from "./AppStyled";
import {useDispatch} from "react-redux";
import {fetchCovid90} from "./Components/redux-tooklit-app/Covid90d";
import {fetchVaccineCovid} from "./Components/redux-tooklit-app/VaccineCovidSlice";
import {fetchCoins24} from "./Components/redux-tooklit-app/coinsSlice";


function App() {
    const dispatch = useDispatch()
    useEffect(()=>{
        dispatch(fetchCovid90());
        dispatch(fetchVaccineCovid());
        dispatch(fetchCoins24())

    },[dispatch])
    const isMobileDevice = useMediaQuery({
            query: "(max-device-width: 1023px)",
    });

    // const isTabletDevice = useMediaQuery({
    //     query: "(min-device-width: 768px)",˚
    // });

    const isLaptop = useMediaQuery({
        query: "(min-device-width: 1024px)",
    });

    // const isDesktop = useMediaQuery({
    //     query: "(min-device-width: 1200px)",
    // });
    //
    // const isBigScreen = useMediaQuery({
    //     query: "(min-device-width: 1201px )",
    // });


    return (
        <CandleProvider2>
            <CovidWorldProvider>
                        <HeaderCSS>
                            <i>{new Date().toLocaleString().split('T')[0]}</i>
                        </HeaderCSS>
            {isLaptop?(<div><AppLaptop/></div>):(<div><AppMobile/></div>)}
            </CovidWorldProvider>

        </CandleProvider2>

    );
}

export default App;
