import React from 'react';
import {SearchCSS, SearchCSS2} from "../../../AppStyledMobile";
import {useTranslation} from "react-i18next";

const SearchCom = (props) => {
    let handleChange = props.handleChangeCoin
    const {t} = useTranslation();

    return (
            <SearchCSS2 >
                <input
                    style={{background:"white" , marginLeft:"100px", border : "1px solid gray",height:"40px", width:"170px"}}
                    type='text'
                    onChange={handleChange}
                    placeholder={t(' 🔍 Search Coin')}
                    id='searchALTS'
                />
            </SearchCSS2>



    )

}
export default SearchCom;