import firebase from 'firebase'

require('dotenv').config()
const config2 = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY2,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN2,
    databaseURL: process.env.DATABASE_URL2,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID2,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET2,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID2,
    appId: process.env.REACT_APP_FIREBASE_APP_ID2,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID2,
};

// firebase.initializeApp(config);
const firebase2 = firebase.initializeApp(config2,"secondary");

// export default firebase.database();
export default firebase2;


