import React  from "react";
import FinChart from "../AppLaptop/Component/Finance/TabCoin/FinChart";


export const Graphic50 = (props) => {
    const CandleChart = props.CandleChart
    const Selected = CandleChart.map(elm=>{return{symbol:elm.symbol, value:elm.value.slice(-50)}})


    return (
        <div>
            {Selected.map((item) => (
                <div>
                    <FinChart data={item.value}></FinChart>
                </div>
                )
            )}
        </div>

    )
}