import React from "react";
import HighLight from "./HighLight";
import {FullPage} from "../../../AppLaptop/AppStyledLaptop";


const Share = () => {
    return (
        <FullPage style={{marginTop:'50px'}}>
                    <HighLight/>
         </FullPage>

    )
}
export default Share;