import './AppMobile.css';
import React from "react";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import "../../i18n";
import "firebase/storage";
import ChartPeriodsMobile from "./Component/Finance/ChartPeriodsMobile";
import BeHealth from "./pages/Healthy/BeHealth";
import HomePage from "./pages/HomePage";
import VietnamCovid from "./pages/Healthy/CovidVN";
import './AppMobile.css'
import InvestCRT from "./Component/Investment/InvestCRT";
import {ContainerLogoMenuTrans, LogoStyle} from "../../AppStyled";
import NavBarLaptop from "../AppComponent/NavBarLaptop";
import TranslateDrop from "../AppComponent/Translate";
import {ECo} from "./pages/Finance/Eco";
import {Fin} from "./pages/Finance/Fin";
import '../../App.css'
import DetailHistoryCoinMobile from "./Component/DetailHistoryCoinMobile";
import DetailHistoryCoin from "../AppLaptop/DetailHistoryCoin";


function AppMobile() {
    return (
        <Router>
            <div>
                <ContainerLogoMenuTrans>
                    <NavBarLaptop/>
                    <LogoStyle>
                        <div style={{
                            textDecoration: 'none',
                            color: '#fe8a71',
                            fontSize: '48px',
                        }}>
                            <div><span style={{color: '#2ab7ca'}}>DtS</span>teller</div>
                        </div>

                    </LogoStyle>
                    <TranslateDrop> </TranslateDrop>
                </ContainerLogoMenuTrans>
            </div>


            <Switch>
                <Route path="/fin" component={Fin}/>
                <Route path="/share" component={ECo}/>
                {/*<Route path="/graphic/:label" component={ChartPeriods}/>*/}
                <Route path="/coin/:symbol" component={DetailHistoryCoinMobile}/>
                <Route path="/health" component={BeHealth}/>
                <Route exact path="/" component={HomePage}/>
                <Route path="/investment" component={InvestCRT}/>
                <Route path="/CovidVN" component={VietnamCovid}/>
            </Switch>
        </Router>
    );
}

export default AppMobile;
