import React, {useContext, useState} from 'react';
import {useTranslation} from "react-i18next";
import {TranslateLap} from "../../AppStyled";
import {CandleContext2} from "../Provider/CandleProvider2";


const TranslateDrop =()=>{
    const { i18n } = useTranslation();
    const {colorBG} = useContext(CandleContext2)
    const [language, setLanguage] = useState("id");

    const handleLangChange = evt => {
        const lang = evt.target.value;
        console.log(lang);
        setLanguage(lang);
        i18n.changeLanguage(lang);
    };
return (
    <TranslateLap>
        <select style={{height:"30px",borderRadius:"10px",backgroundColor:`${colorBG.backgroundColor}`, color:`${colorBG.color}`}} onChange={handleLangChange} value={language}>
            <option value="en">EN</option>
            <option value="vn">VN</option>
        </select>
    </TranslateLap>

)}

export default TranslateDrop;