import React from "react";
import {useTranslation} from "react-i18next";
import {v4 as uuid} from "uuid";
import Infinite from "react-infinite";
import {Link, useHistory} from 'react-router-dom';

import Popup from "reactjs-popup";
import {
    ButtonLager10,
    ButtonSingleCoin2,
    ButtonTopFinPopUp2, ContainerPairCoinCSS1,
    ContentPopUp, HoverPair,
    PairsCoinCSS, SpanButtonSymbol
} from "./AppStyledLaptop";
import {ContainerButtonTable} from "../AppMobile/AppStyledMobile";
import {ButtonSEELarger, TitleCard} from "../../AppStyled";

const Crypto24hr = React.memo((props) => {
    let result = props.TabData
    const history = useHistory();
    function handleRowClick(item) {
        history.push(`/coin/${item.symbol}`);
    }

    const {t} = useTranslation();

    const MaxChange = result.sort(function (a, b) {
        return parseFloat(b.priceChangePercent) - parseFloat(a.priceChangePercent);
    });

    return (

        <ContainerPairCoinCSS1 style ={{ }} >

            <div>
                <ContainerButtonTable style ={{ border:"0.1px solid gray",width:"700px",}}>
                    <ButtonLager10>
                        <Popup trigger={() => (
                            <ButtonTopFinPopUp2  className="button">
                                <TitleCard>{t("Pair")} <em style={{fontSize: "11px"}}></em></TitleCard>
                            </ButtonTopFinPopUp2>
                        )}
                               position="right center"
                               closeOnDocumentClick>
                            <ContentPopUp> {t('base asset/quote asset')} </ContentPopUp>
                        </Popup>
                    </ButtonLager10>
                        <ButtonLager10 >
                            <Popup trigger={() => (
                                <ButtonTopFinPopUp2  className="button">
                                    <TitleCard>{t("Volume")} <br/><em style={{fontSize: "11px"}}>($)</em></TitleCard>
                                </ButtonTopFinPopUp2>
                            )}
                                   position="right center"
                                   closeOnDocumentClick>
                                <ContentPopUp>
                                    <p style={{whiteSpace:'pre-wrap'}}>{t('Higher trading volumes are considered more positive than lower trading volumes because they mean more liquidity and better order execution')}</p>
                                    <Link to ='/eco/high-light'>{t("and more")}</Link>
                                </ContentPopUp>
                            </Popup>

                        </ButtonLager10>
                        <ButtonLager10>
                            <Popup trigger={() => (
                                <ButtonTopFinPopUp2  className="button">
                                    <TitleCard>{t("Traders")} <br/><em style={{fontSize: "11px"}}>({t("person")})</em> ️</TitleCard>
                                </ButtonTopFinPopUp2>
                            )}
                                   position="right center"
                                   closeOnDocumentClick

                            >
                                <ContentPopUp> {t('')}
                                    <Link to ='/eco'>{t("and more")}</Link>
                                </ContentPopUp>

                            </Popup>

                        </ButtonLager10>
                        <ButtonLager10>
                            <Popup trigger={() => (
                                <ButtonTopFinPopUp2  className="button">
                                    <TitleCard>{t("Gain")} <br/>   <em style={{fontSize: "11px"}}>(%)</em> ️</TitleCard>
                                </ButtonTopFinPopUp2>
                            )}
                                   position="right center"
                                   closeOnDocumentClick

                            >
                                <ContentPopUp> {t('')}
                                    <Link to ='/eco'>{t("and more")}</Link>
                                </ContentPopUp>

                            </Popup>

                        </ButtonLager10>

                        <ButtonLager10>
                            <Popup trigger={() => (
                                <ButtonTopFinPopUp2  className="button">
                                    <TitleCard>{t("weightedAvg")} <em style={{fontSize: "11px"}}></em> ️</TitleCard>
                                </ButtonTopFinPopUp2>
                            )}
                                   position="right center"
                                   closeOnDocumentClick
                            >
                                <ContentPopUp> {t('')}
                                    <Link to ='/eco'>{t("and more")}</Link>
                                </ContentPopUp>

                            </Popup>

                        </ButtonLager10>

                </ContainerButtonTable>

                <div style={{width:"700px", border:"0.1px solid gray"}}>
                    <Infinite
                        containerHeight={5000}
                        elementHeight={80}>
                        {MaxChange.length>0?(<div>
                            {MaxChange.map(item=>(<div>
                                {item.priceChangePercent>=0?(<div>
                                    <PairsCoinCSS style ={{ }}>
                                        <ButtonLager10>
                                            <HoverPair>
                                                <ButtonSEELarger key={uuid()} onClick={() => handleRowClick(item)}>
                                                    <SpanButtonSymbol>{item.symbol}
                                                    </SpanButtonSymbol>
                                                </ButtonSEELarger>
                                            </HoverPair>
                                        </ButtonLager10>

                                            <ButtonLager10  key={uuid()}>
                                                    {new Intl.NumberFormat().format(item.volume)}
                                            </ButtonLager10>
                                            <ButtonLager10>
                                                    {item.count}
                                            </ButtonLager10>

                                            <ButtonLager10 key={uuid()}>

                                                    <div style={{color: "#00b159"}}>
                                                        {Number.parseFloat(item.priceChangePercent).toFixed(5)}%
                                                    </div>

                                            </ButtonLager10>

                                            <ButtonLager10>
                                                    {item.weightedAvgPrice}
                                            </ButtonLager10>
                                    </PairsCoinCSS>


                                </div>):(<div></div>)}
                            </div>))}
                        </div>):(<div></div>)}

                    </Infinite>
                    <br/>
                </div>
            </div>

            <div style={{marginLeft: "20px"}}>
                <ContainerButtonTable style ={{ border:"0.1px solid gray",width:"700px",}}>
                    <ButtonLager10>
                        <Popup trigger={() => (
                            <ButtonTopFinPopUp2  className="button">
                                <TitleCard>{t("Pair")} <em style={{fontSize: "11px"}}></em></TitleCard>
                            </ButtonTopFinPopUp2>
                        )}
                               position="right center"
                               closeOnDocumentClick>
                            <ContentPopUp> {t('base asset/quote asset')} </ContentPopUp>
                        </Popup>
                    </ButtonLager10>
                        <ButtonLager10 >
                            <Popup trigger={() => (
                                <ButtonTopFinPopUp2  className="button">
                                    <TitleCard>{t("Volume")} <br/> <em style={{fontSize: "11px"}}>($)</em></TitleCard>
                                </ButtonTopFinPopUp2>
                            )}
                                   position="right center"
                                   closeOnDocumentClick>
                                <ContentPopUp>
                                    <p style={{whiteSpace:'pre-wrap'}}>{t('Higher trading volumes are considered more positive than lower trading volumes because they mean more liquidity and better order execution')}</p>
                                    <Link to ='/eco/high-light'>{t("and more")}</Link>
                                </ContentPopUp>
                            </Popup>

                        </ButtonLager10>
                        <ButtonLager10>
                            <Popup trigger={() => (
                                <ButtonTopFinPopUp2  className="button">
                                    <TitleCard>{t("Traders")} <br/> <em style={{fontSize: "11px"}}>({t("person")})</em> ️</TitleCard>
                                </ButtonTopFinPopUp2>
                            )}
                                   position="right center"
                                   closeOnDocumentClick

                            >
                                <ContentPopUp> {t('')}
                                    <Link to ='/eco'>{t("and more")}</Link>
                                </ContentPopUp>

                            </Popup>

                        </ButtonLager10>
                        <ButtonLager10>
                            <Popup trigger={() => (
                                <ButtonTopFinPopUp2  className="button">
                                    <TitleCard>{t("Loss")} <br/> <em style={{fontSize: "11px"}}>(%)</em> ️</TitleCard>
                                </ButtonTopFinPopUp2>
                            )}
                                   position="right center"
                                   closeOnDocumentClick

                            >
                                <ContentPopUp> {t('')}
                                    <Link to ='/eco'>{t("and more")}</Link>
                                </ContentPopUp>

                            </Popup>

                        </ButtonLager10>

                        <ButtonLager10>
                            <Popup trigger={() => (
                                <ButtonTopFinPopUp2  className="button">
                                    <TitleCard>{t("weightedAvg")} <br/> <em style={{fontSize: "11px"}}></em> ️</TitleCard>
                                </ButtonTopFinPopUp2>
                            )}
                                   position="right center"
                                   closeOnDocumentClick
                            >
                                <ContentPopUp> {t('')}
                                    <Link to ='/eco'>{t("and more")}</Link>
                                </ContentPopUp>

                            </Popup>

                        </ButtonLager10>
                </ContainerButtonTable>


                <div style={{width:"700px", border:"0.1px solid gray"}}>
                    <Infinite
                        containerHeight={5000}
                        elementHeight={80}>
                        {MaxChange.length>0?(<div>
                            {MaxChange.map(item=>(<div>
                                {item.priceChangePercent<0?(<div>
                                        <PairsCoinCSS style ={{ }}>
                                            <ButtonLager10>
                                                <HoverPair>
                                                    <ButtonSEELarger key={uuid()} onClick={() => handleRowClick(item)}>
                                                        <SpanButtonSymbol>{item.symbol}
                                                        </SpanButtonSymbol>
                                                    </ButtonSEELarger>
                                                </HoverPair>
                                            </ButtonLager10>

                                                <ButtonLager10  key={uuid()}>
                                                        {new Intl.NumberFormat().format(item.volume)}
                                                </ButtonLager10>
                                                <ButtonLager10 key={uuid()} >
                                                        {item.count}
                                                </ButtonLager10>

                                                <ButtonLager10 key={uuid()}>

                                                        <div style={{color: "#ee4035"}}>
                                                            {Number.parseFloat(item.priceChangePercent).toFixed(5)}%
                                                        </div>

                                                </ButtonLager10>

                                                <ButtonLager10>
                                                    <div key={uuid()}>
                                                        {item.weightedAvgPrice}
                                                    </div>
                                                </ButtonLager10>
                                        </PairsCoinCSS>


                                </div>):(<div></div>)}
                            </div>))}
                        </div>):(<div></div>)}

                    </Infinite>
                    <br/>
                </div>

            </div>

        </ContainerPairCoinCSS1>
    );
});
export default Crypto24hr;
