import React from "react";
import {
    Route,
     useRouteMatch, Switch,
} from "react-router-dom";
import {
    EcoCSS,
    FullPage,

} from "../../AppStyledLaptop";

import HighLight from "./FireBaseStore/HighLight";

export const ECo = () => {
    let {path} = useRouteMatch();
    return (
        <FullPage>
            <EcoCSS>

                <Switch>
                    <Route path={`${path}/stock`}/>
                    <Route path={`${path}/high-light`} component={HighLight}/>
                </Switch>
            </EcoCSS>

        </FullPage>

    )
}