import React from "react";
import {useTranslation} from "react-i18next";
import {v4 as uuid} from "uuid";
import Infinite from "react-infinite";
import {Link, useHistory} from 'react-router-dom';

import Popup from "reactjs-popup";
import {MaxStasCSS, MGroupHeaderTableCSS} from "../AppStyledMobile";
import {
    ButtonTableMB,
    ButtonTopFinPopUpMB,
    CardTopSwitch2, ContentPopUp, HoverPair,
    MarginButton330, MarginButtonTableMB,
    MarginLeftRight2, MaxMinPairsCoinCSS, SpanButtonSymbol
} from "../../AppLaptop/AppStyledLaptop";
import {ButtonSEELarger, ButtonSEELarger2} from "../../../AppStyled";

const Crypto24hrMobile = React.memo((props) => {
    let result = props.TabData
    const history = useHistory();
    function handleRowClick(item) {
        history.push(`/coin/${item.symbol}`);
    }

    const {t} = useTranslation();
    return (
        <div>
            <MaxStasCSS>
                <CardTopSwitch2 style={{background: 'inherit', color: 'inherit'}}>
                    <MarginLeftRight2>
                        <MGroupHeaderTableCSS style={{}}>
                            <ButtonTableMB>
                                <Popup trigger={() => (
                                    <ButtonTopFinPopUpMB style={{marginLeft: '0px', paddingLeft: '5px'}}
                                                         className="button">
                                        <strong style={{}}>{t("Pair")} <em style={{fontSize: "11px"}}></em></strong>
                                        <br/>
                                        <strong style={{}}>{t("PriceChange")} <em
                                            style={{fontSize: "11px"}}>%</em></strong>
                                    </ButtonTopFinPopUpMB>
                                )}
                                       position="bottom left "
                                       closeOnDocumentClick

                                >
                                    <ContentPopUp>
                                        <p style={{whiteSpace: 'pre-wrap'}}>{t('base asset/quote asset')} </p>
                                        <Link to='/share'>{t("and more")}</Link>
                                    </ContentPopUp>

                                </Popup>


                            </ButtonTableMB>
                            <MarginButton330>
                                <ButtonTableMB>
                                    <Popup trigger={() => (
                                        <ButtonTopFinPopUpMB className="button">
                                            <strong style={{
                                                paddingLeft: '15px', paddingTop: '15px'
                                            }}>{t("Volume")}<em
                                                style={{fontSize: "11px"}}>($)</em></strong>
                                            <br/>
                                            <strong style={{
                                                color: 'orange',
                                                paddingLeft: '15px',
                                                paddingTop: '15px'
                                            }}>VWAP</strong><em style={{fontSize: "11px"}}>($)</em>
                                        </ButtonTopFinPopUpMB>
                                    )}
                                           position="bottom right"
                                           closeOnDocumentClick

                                    >
                                        <ContentPopUp>
                                            <p style={{whiteSpace: 'pre-wrap'}}>{t('Higher trading volumes are considered more positive than lower trading volumes because they mean more liquidity and better order execution')}</p>
                                            <Link to='/share'>{t("and more")}</Link>
                                        </ContentPopUp>

                                    </Popup>
                                </ButtonTableMB>
                            </MarginButton330>

                        </MGroupHeaderTableCSS>
                        <Infinite containerHeight={300}
                                  elementHeight={80}>
                            {result.map((item) =>
                                (
                                    <MaxMinPairsCoinCSS>
                                        <ButtonTableMB key={uuid()}>
                                            <HoverPair>
                                                <ButtonSEELarger key={uuid()} onClick={() => handleRowClick(item)}>
                                                    <SpanButtonSymbol>{item.symbol}
                                                    </SpanButtonSymbol>
                                                </ButtonSEELarger>
                                            </HoverPair>
                                            <ButtonSEELarger2>{item.priceChangePercent>0?(<div style ={{color:"white"}}>
                                                {item.priceChangePercent}
                                            </div>):(<div style ={{color:"red"}}>{item.priceChangePercent}</div>)}</ButtonSEELarger2>
                                        </ButtonTableMB>
                                        <MarginButtonTableMB>
                                            <ButtonTableMB key={uuid()}>
                                                <span> {new Intl.NumberFormat('en-US', {style: 'decimal'}).format(item.volume)}</span>
                                                <br/>
                                                <span
                                                    style={{color: 'orange'}}>{parseFloat(item.weightedAvgPrice)}</span>
                                            </ButtonTableMB>
                                        </MarginButtonTableMB>

                                    </MaxMinPairsCoinCSS>

                                ))}
                        </Infinite>
                    </MarginLeftRight2>


                </CardTopSwitch2>


            </MaxStasCSS>
        </div>

    );
});
export default Crypto24hrMobile;
