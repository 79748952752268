import React, {useEffect, useState, createContext   } from "react";
import {UserServiceHealthy} from "../../ulitiesComponent/forHealthy/UserServiceHealthy";
import {ButtonCard, TitleCard} from "../../../AppStyled";
import {GridContainer, LayoutRow} from "../../AppLaptop/AppStyledLaptop";
import {v4 as uuidv4} from "uuid";
import TopCom from "../../AppLaptop/Component/Health/Covid/CovidComponent/30d/RankCom";
export const CovidWorldContext = createContext(null);
export const CovidWorldProvider = props => {
    const [covidYesterday, setCovidYesterday] = useState([])
    const [vietNamCovid, setVietNamCovid] = useState([])
    const [vnHistoryCv, setVNHistoryCv] = useState([])
    const [homePageChart, setHomePageChart] = useState([])
    const [search, setSearch] = useState('')
    const [visibleList, setVisibleList] = useState(5);

    const handleChange = (e) => {
        setSearch(e.target.value);
    };
    const filteredCountry =
        covidYesterday.filter((country) => {
            return country.country.toLowerCase().includes(search.toLowerCase());
        })



    useEffect(() => {
        const getCovidYesterday = () => {
            UserServiceHealthy.getCovidTodaybyCountry()
                .then(res => {
                    setCovidYesterday(res)
                })


            fetch('https://static.pipezero.com/covid/data.json')
                .then(response => response.json())
                .then(data => {
                    setVietNamCovid(data.locations);
                    setVNHistoryCv(data.overview)
                });

            UserServiceHealthy.getHomePageChart()
                .then(res => {
                    setHomePageChart(res)
                })
        }
        getCovidYesterday()

    }, [])
    const filterVNdtTopGreater1k = vietNamCovid.sort((a, b) => b.casesToday - a.casesToday).filter(item => item.casesToday > 1000)
    const filterVNdtSmaller1k = vietNamCovid.sort((a, b) => b.casesToday - a.casesToday).filter(item => item.casesToday <= 1000)
    const cardComponent1 = filterVNdtSmaller1k.slice(0, visibleList).map((item, i) => {
        return (
            <LayoutRow>
                <TitleCard style={{width: '150px',padding:'15px'}} key={i + item.name}>{item.name}</TitleCard>
                <ButtonCard style={{width: '100px',padding:'15px'}} key={i + item.name}>+{new Intl.NumberFormat('en').format(item.casesToday)}</ButtonCard>
            </LayoutRow>
        )
    });

    const cardComponent3 = filteredCountry.slice(0, visibleList).map((item, i) => {
        return (
            <LayoutRow style ={{border:'0.1px solid gray',width:"360px"}}>
                <TitleCard style={{width: '150px'}} key={i + item.name}>{item.country}</TitleCard>
                <ButtonCard  key={i + item.name}>{new Intl.NumberFormat().format(item.todayCases)}</ButtonCard>
            </LayoutRow>
        )
    });
    const cardComponent2 = filterVNdtTopGreater1k.slice(0, visibleList).map((item, i) => {
        return (
            <LayoutRow>
                <TitleCard style={{width: '150px',padding:'15px'}} key={i + item.name}>{item.name}</TitleCard>
                <ButtonCard style={{width: '100px',padding:'15px'}} key={i + item.name}>+{new Intl.NumberFormat('en').format(item.casesToday)}</ButtonCard>
            </LayoutRow>
        )
    });

    return (
        <CovidWorldContext.Provider value={{
            handleChange, cardComponent2, cardComponent1, filterVNdtSmaller1k, setVisibleList, filterVNdtTopGreater1k,
            homePageChart, cardComponent3,search, filteredCountry, vnHistoryCv, vietNamCovid, covidYesterday
        }}>
            {props.children}
        </CovidWorldContext.Provider>
    );
}


