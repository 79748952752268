import React from "react";
import FinChart from "../FinChart";
import {MarginChartInfo} from "../../../../../AppLaptop/AppStyledLaptop";


export const GraphicMB = (props) => {
    const CandleChart = props.CandleChart
    return (
        <MarginChartInfo>
            {CandleChart.map((item) => (
                    <div style ={{marginLeft:"15px"}}>
                        <FinChart data={item.value}></FinChart>
                    </div>
                )
            )}
        </MarginChartInfo>

    )
}