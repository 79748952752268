import {useEffect, useState} from "react";
import axios from "axios";

const useMarket24 = () => {

    const [coin24, setCoin24] = useState([])
    useEffect(() => {
        async function market24h() {
            try {
                const res = await axios.get("https://api.binance.com/api/v3/ticker/24hr");
                const response = res.data.map(chil=>{return{
                    weightedAvgPrice:chil.weightedAvgPrice,
                    symbol:chil.symbol,
                    priceChangePercent:chil.priceChangePercent.trim(),
                    volume:parseFloat(chil.volume.trim()),
                    count: chil.count
                }});
                setCoin24(response)
            } catch (e) {
                console.log(e)
            }

        }

        market24h()
    }, [])
    return {coin24}
}

export default useMarket24

