// export const AsiaCountry =  "Afghanistan,Armenia,Azerbaijan,Bahrain,Bangladesh,Bhutan,Brunei,Cambodia,China,Cyprus,Georgia,Hong Kong,India,	Indonesia,	Iran,	Iraq,	Israel,	Japan,	Jordan,	Kazakhstan,	Kuwait,	Kyrgyzstan,	Lao Peoples Democratic Republic,	Lebanon,	Macao,	Malaysia,	Maldives,	Mongolia,	Myanmar,	Nepal,	Oman,	Pakistan,	Palestine,	Philippines,	Qatar,	S. Korea,	Saudi Arabia,	Singapore,	Sri Lanka,	Syrian Arab Republic,	Taiwan,	Tajikistan,	Thailand,	Timor-Leste,	Turkey,	UAE,	Uzbekistan,	Vietnam,	Yemen"
// export const EuropeCountry =  "Albania, Andorra, Austria, Belarus, Belgium, Bosnia, Bulgaria, Channel Islands, Croatia, Czechia, Denmark, Estonia, Faroe Islands, Finland, France, Germany, Gibraltar, Greece, Holy See (Vatican City State), Hungary, Iceland, Ireland, Isle of Man, Italy, Latvia, Liechtenstein, Lithuania, Luxembourg, Macedonia, Malta, Moldova, Monaco, Montenegro, Netherlands, Norway, Poland, Portugal, Romania, Russia, San Marino, Serbia, Slovakia, Slovenia, Spain, Sweden, Switzerland, UK, Ukraine"
// export const NorthAmericaCountry ="Anguilla,	Antigua and Barbuda,	Aruba,	Bahamas,	Barbados,	Belize,	Bermuda,	British Virgin Islands,	Canada,	Caribbean Netherlands,	Cayman Islands,	Costa Rica,	Cuba,	Curaçao,	Dominica,	Dominican Republic,	El Salvador,	Greenland,	Grenada,	Guadeloupe,	Guatemala,	Haiti,	Honduras,	Jamaica,	Martinique,	Mexico,	Montserrat,	Nicaragua,	Panama,	Saint Kitts and Nevis,	Saint Lucia,	Saint Martin,	Saint Pierre Miquelon,	Saint Vincent and the Grenadines,	Sint Maarten,	St. Barth,	Trinidad and Tobago,	Turks and Caicos Islands,	USA"
// export const SouthAmericaCountry ="Argentina,	Bolivia,	Brazil,	Chile,	Colombia,	Ecuador,	Falkland Islands (Malvinas),	French Guiana,	Guyana,	Paraguay,	Peru,	Suriname,	Uruguay,	Venezuela";
// export const AfricaCountry ="Algeria,	Angola,	Benin,	Botswana,	Burkina Faso,	Burundi,	Cabo Verde,	Cameroon,	Central African Republic,	Chad,	Comoros,	Congo,	Côte d'Ivoire,	DRC,	Djibouti,	Egypt,	Equatorial Guinea,	Eritrea,	Ethiopia,	Gabon,	Gambia,	Ghana,	Guinea,	Guinea-Bissau,	Kenya,	Lesotho,	Liberia,	Libyan Arab Jamahiriya,	Madagascar,	Malawi,	Mali,	Mauritania,	Mauritius,	Mayotte,	Morocco,	Mozambique,	Namibia,	Niger,	Nigeria,	Rwanda,	Réunion,	Saint Helena,	Sao Tome and Principe,	Senegal,	Seychelles,	Sierra Leone,	Somalia,	South Africa,	South Sudan,	Sudan,	Swaziland,	Tanzania,	Togo,	Tunisia,	Uganda,	Western Sahara,	Zambia,	Zimbabwe";
// export const AustraliaOceaniaCountry ="Australia,	Fiji,	French Polynesia,	Kiribati,	Marshall Islands,	Micronesia,	New Caledonia,	New Zealand,	Palau,	Papua New Guinea,	Samoa,	Solomon Islands,	Tonga,	Vanuatu,	Wallis and Futuna"

export const Europe =   ['Albania', 'Andorra', 'Austria', 'Belarus', 'Belgium', 'Bosnia', 'Bulgaria', 'Channel Islands', 'Croatia', 'Czechia', 'Denmark', 'Estonia', 'Faroe Islands', 'Finland', 'France', 'Germany', 'Gibraltar', 'Greece', 'Holy See (Vatican City State)', 'Hungary', 'Iceland', 'Ireland', 'Isle of Man', 'Italy', 'Latvia', 'Liechtenstein', 'Lithuania', 'Luxembourg', 'Macedonia', 'Malta', 'Moldova', 'Monaco', 'Montenegro', 'Netherlands', 'Norway', 'Poland', 'Portugal', 'Romania', 'Russia', 'San Marino', 'Serbia', 'Slovakia', 'Slovenia', 'Spain', 'Sweden', 'Switzerland', 'UK', 'Ukraine']
export const Asia =   ["Afghanistan",	"Armenia",	"Azerbaijan",	"Bahrain",	"Bangladesh",	"Bhutan",	"Brunei",	"Cambodia",	"China",	"Cyprus",	"Georgia",	"Hong Kong",	"India",	"Indonesia",	"Iran",	"Iraq",	"Israel",	"Japan",	"Jordan",	"Kazakhstan",	"Kuwait",	"Kyrgyzstan",	"Lao People's Democratic Republic",	"Lebanon",	"Macao",	"Malaysia",	"Maldives",	"Mongolia",	"Myanmar",	"Nepal",	"Oman",	"Pakistan",	"Palestine",	"Philippines",	"Qatar",	"S. Korea",	"Saudi Arabia",	"Singapore",	"Sri Lanka",	"Syrian Arab Republic",	"Taiwan",	"Tajikistan",	"Thailand",	"Timor-Leste",	"Turkey",	"UAE",	"Uzbekistan",	"Vietnam",	"Yemen"]
export const  NorthAmerica =["Anguilla",	"Antigua and Barbuda",	"Aruba",	"Bahamas",	"Barbados",	"Belize",	"Bermuda",	"British Virgin Islands",	"Canada",	"Caribbean Netherlands",	"Cayman Islands",	"Costa Rica",	"Cuba",	"Curaçao",	"Dominica",	"Dominican Republic",	"El Salvador",	"Greenland",	"Grenada",	"Guadeloupe",	"Guatemala",	"Haiti",	"Honduras",	"Jamaica",	"Martinique",	"Mexico",	"Montserrat",	"Nicaragua",	"Panama",	"Saint Kitts and Nevis",	"Saint Lucia",	"Saint Martin",	"Saint Pierre Miquelon",	"Saint Vincent and the Grenadines",	"Sint Maarten",	"St. Barth",	"Trinidad and Tobago",	"Turks and Caicos Islands",	"USA"]
export const SouthAmerica =["Argentina",	"Bolivia",	"Brazil",	"Chile",	"Colombia",	"Ecuador",	"Falkland Islands (Malvinas)",	"French Guiana",	"Guyana",	"Paraguay",	"Peru",	"Suriname",	"Uruguay",	"Venezuela"];
export const Africa = ["Algeria",	"Angola",	"Benin",	"Botswana",	"Burkina Faso",	"Burundi",	"Cabo Verde",	"Cameroon",	"Central African Republic",	"Chad",	"Comoros",	"Congo",	"Côte d'Ivoire",	"DRC",	"Djibouti",	"Egypt",	"Equatorial Guinea",	"Eritrea",	"Ethiopia",	"Gabon",	"Gambia",	"Ghana",	"Guinea",	"Guinea-Bissau",	"Kenya",	"Lesotho",	"Liberia",	"Libyan Arab Jamahiriya",	"Madagascar",	"Malawi",	"Mali",	"Mauritania",	"Mauritius",	"Mayotte",	"Morocco",	"Mozambique",	"Namibia",	"Niger",	"Nigeria",	"Rwanda",	"Réunion",	"Saint Helena",	"Sao Tome and Principe",	"Senegal",	"Seychelles",	"Sierra Leone",	"Somalia",	"South Africa",	"South Sudan",	"Sudan",	"Swaziland",	"Tanzania",	"Togo",	"Tunisia",	"Uganda",	"Western Sahara",	"Zambia",	"Zimbabwe"];
export const AustraliaOceania =["Australia",	"Fiji",	"French Polynesia",	"Kiribati",	"Marshall Islands",	"Micronesia",	"New Caledonia",	"New Zealand",	"Palau",	"Papua New Guinea",	"Samoa",	"Solomon Islands",	"Tonga",	"Vanuatu",	"Wallis and Futuna"]


