import React from "react";
import {useList} from "react-firebase-hooks/database";
import  {getAll} from '../../../../../service/EconomicDatabase';
import {useTranslation} from "react-i18next";
import {HighLightContainer, ItemFooter, ItemMain, ItemMenu, ItemRight, TitleEcoSpot} from "../../../../../AppStyled";
import {FacebookShareButton,TelegramShareButton,TwitterShareButton} from "react-share";
import { FacebookIcon, TwitterIcon,TelegramIcon } from "react-share";
import {LayoutRow} from "../../../AppStyledLaptop";


const Spots = React.memo(() => {
    const [finnews, loading, error] = useList(getAll());
    const {t} = useTranslation();
    return (
        <div  >
            {error && <strong>Error: {error}</strong>}
            {loading && <span style={{color: "darkGray"}}>✨...</span>}
            <div >
                {!loading &&
                    finnews &&
                    finnews.map((finnew, index) => (
                            <HighLightContainer>

                                    <ItemMenu>
                                        <TitleEcoSpot key={index}>
                                            {t(`${finnew.val().name}`)}
                                        </TitleEcoSpot>
                                    </ItemMenu>

                                <ItemMain>
                                    <img src ={finnew.val().url }
                                         style={{ padding:"10px" }}/>
                                    <br/>

                                </ItemMain>
                                <ItemRight>
                                    <p style={{whiteSpace: "pre-wrap", border:"none"}}>  {t(`${finnew.val().content}`)} </p>
                                    <p>{t("Share it")}</p>
                                    <LayoutRow>
                                        <FacebookShareButton
                                            url={"https://dts.pages.dev/"}
                                            hashtag={["coin", "covid"]}
                                        >
                                            <FacebookIcon size={32} round />
                                        </FacebookShareButton>
                                        <TwitterShareButton
                                            style = {{marginLeft:"10px"}}
                                            url={"https://dts.pages.dev/"}
                                            hashtags={["coin", "covid"]}
                                        >
                                            <TwitterIcon size={32} round />
                                        </TwitterShareButton>
                                        <TelegramShareButton
                                            style = {{marginLeft:"10px"}}
                                            url={"https://dts.pages.dev/"}
                                            hashtags={["coin", "covid"]}
                                        >
                                            <TelegramIcon size={32} round />
                                        </TelegramShareButton>
                                    </LayoutRow>
                                </ItemRight>

                                <ItemFooter>
                                    <p style={{whiteSpace: "pre-wrap", border:"none"}}>  {t(`${finnew.val().description1}`)}</p>
                                    <p>{t("Share it")}</p>
                                    <LayoutRow>
                                        <FacebookShareButton
                                            url={"https://dts.pages.dev/"}
                                            hashtag={["coin", "covid"]}
                                        >
                                           <FacebookIcon size={32} round />
                                        </FacebookShareButton>
                                        <TwitterShareButton
                                            url={"https://dts.pages.dev/"}
                                            hashtags={["coin", "covid"]}
                                        >
                                            <TwitterIcon size={32} round />
                                        </TwitterShareButton>
                                        <TelegramShareButton
                                            url={"https://dts.pages.dev/"}
                                            hashtags={["coin", "covid"]}
                                        >
                                            <TelegramIcon size={32} round />
                                        </TelegramShareButton>
                                    </LayoutRow>


                                </ItemFooter>
                            </HighLightContainer>

                    ))}
            </div>
            <br/>
        </div>
    );
});

export default Spots;
