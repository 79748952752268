import React from 'react';
import {useSelector} from "react-redux";
import Crypto24hrMobile from "../Crypto24hrMobile";
import {Coins24Selector} from "../../../redux-tooklit-app/coinsSlice";
import useFilter from "../../../hooks/useFilter";
import {FullPage} from "../../../AppLaptop/AppStyledLaptop";
import SearchCom from "./SearchCoin/SearchCom";

const SpotMarketCoin = () => {
    const coins24 = useSelector(Coins24Selector)
    const {handleChange: handleChangeETF, filteredCoin: SearchCoin24s} = useFilter(coins24)

    return (

        <FullPage style={{marginTop: "100px"}}>
            <SearchCom handleChangeCoin ={handleChangeETF} />
            <Crypto24hrMobile TabData={SearchCoin24s}/>

        </FullPage>)
}
export default SpotMarketCoin