import React from "react";
import {useTranslation} from "react-i18next";
import styled from 'styled-components';
import {Link} from "react-router-dom";
import { PlotHOMEPAGECSS} from "../AppStyledMobile";
import HomepageModel from "../../AppComponent/HomepageModel";
import {FullPage} from "../../AppLaptop/AppStyledLaptop";


const AboutDAPText = styled.div`
  margin-top: 40px;
  white-space: pre-wrap;
`

const AboutDAStyled = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;

`

const HomePage = () => {
    const {t} = useTranslation();
    return (
        <FullPage style ={{marginTop:'50px', paddingLeft:'25px', paddingTop:'25px'}}>
            <AboutDAPText >
                <p style={{
                    fontSize: "20px",
                }}> "{t("Data reflects the past, the present, and the future orientation. Therefore data are stories of the life as well.")}" </p>
                <p style={{
                }}>
                    ️{t("The story of health")} </p>
                <p style={{
                }}>✍️️{t("November 2019 SAR flu was detected and quickly became a global pandemic named COVID19."
                )} </p>
                <p style={{
                }}>{t("The pandemic is causing economic and human health losses.")}</p>
                <Link to="/health" style={{fontStyle:"italic",color:'inherit'
                }}>{t(
                    "and more")} </Link>
            </AboutDAPText>
            <AboutDAStyled>
                    <HomepageModel/>
            </AboutDAStyled>

            <br/>  <br/>

            <PlotHOMEPAGECSS>
                <p style={{
                }}>{t("The impact of the coronavirus crisis on finance & economy")} </p>
                <p style={{

                }}>{t("The value of exports of goods and services decreased during the 2019-2020 pandemic...")}

                </p>

            </PlotHOMEPAGECSS>
            <Link to="/eco-fin"style={{fontStyle:"italic",color:'inherit'
            }}
            >{t(
                "and more")} </Link>


        </FullPage>


    )
}
export default HomePage;